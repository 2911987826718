<template>
    <div>
        <q-card flat bordered class="my-card add">
            <q-card-section>
                <!-- <tabulationdate
            :orderList='checkDatas.checkReportFilesList'
            :orderid="checkAllDatas.orderId"
            :prop='"informationFileName"'
            :label='"检查报告"'
        /> -->
                <el-table
                    :data="checkDatas.checkReportFilesList"
                    style="width: 100%"
                >
                    <el-table-column
                        show-overflow-tooltip
                        label="检查报告"
                        prop="informationFileName"
                    ></el-table-column>
                    <el-table-column
                        label=""
                        prop="createTime"
                    ></el-table-column>
                    <!-- 空白占位格 -->
                    <el-table-column align='center' label="" width='130'>
            
                    </el-table-column>
                    <el-table-column align="right" width='150'>
                        <template slot="header"> </template>
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                icon="iconfont icon-xiazai"
                                @click="download(scope.row)"
                            ></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </q-card-section>
        </q-card>

        <q-card flat bordered class="my-card add">
            <q-card-section>
                <!-- <tabulationdate
        :orderList='checkDatas.dcmReportFilesList'
        :orderid="checkAllDatas.orderId"
        :prop='"informationFileName"'
        :label='"影像资料"'
        ></tabulationdate> -->
                <el-table
                    v-loading="ordervisibles"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(55,55,55,0.2)"
                    :data="checkDatas.dcmReportFilesList"
                    style="width: 100%"
                >
                    <el-table-column
                        show-overflow-tooltip
                        label="影像资料"
                        prop="informationFileName"
                    ></el-table-column>
                    <el-table-column
                        label=""
                        prop="createTime"
                    ></el-table-column>
                    <el-table-column align='center' label="资料状态" width='130'>
                        <template slot="header"> 
                            资料状态<i style="margin-left: 12px;" class="el-icon-refresh-left" @click="refresh"></i>
                        </template>

                        <template slot-scope="scope">
                            <q-chip v-if="scope.row.processFlg != 1" square :color="scope.row.processFlg==0?'primary':'red'" text-color="white">
                                {{scope.row.processFlg==0?'处理中':'处理失败'}}
                                <q-tooltip v-if="scope.row.errMsg">
                                    {{scope.row.errMsg}}
                                </q-tooltip>
                            </q-chip>
                        </template>
                    </el-table-column>
                    <el-table-column align="right" width='150'>
                        <template slot="header"> </template>
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                icon="iconfont icon-xiazai"
                                @click="yxdownload(scope.row)"
                            ></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </q-card-section>
        </q-card>
        <!-- 发票信息 -->
        <q-card flat bordered class="my-card add" v-if="reconstructionAgree">
            <q-card-section>
                <el-table
                    :data="checkDatas.invoiceList"
                    style="width: 100%"
                >
                    <el-table-column
                        show-overflow-tooltip
                        label="发票信息"
                        prop="invoiceFileName"
                    ></el-table-column>
                    <el-table-column
                        label=""
                        prop="createTime"
                    ></el-table-column>
                    <!-- 空白占位格 -->
                    <el-table-column align='center' label="" width='130'>
            
                    </el-table-column>
                    <el-table-column align="right" width='150'>
                        <template slot="header"> </template>
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                icon="iconfont icon-xiazai"
                                @click="invoicedownload(scope.row)"
                            ></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </q-card-section>
        </q-card>
        
    </div>
</template>
<script>

export default {
    props: {
        checkAllData: {
            //修改订单的数据
            type: Object,
            required: true,
        },
        checkData: {
            //所选的城市
            type: Object,
            required: true,
        },
        ordervisible:{
            type: Boolean,
        },
        // 判断是否打开了三维重建的订单详细
        reconstructionAgree:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return {

        }
    },
    created(){
    },
    components:{
        // yxDown
    },
    computed:{
        checkAllDatas: {
            get() {
                return this.checkAllData;
            },
            set(v) {
                this.$emit("update:checkAllData", v);
            },
        },
        checkDatas: {
            get() {
                return this.checkData;
            },
            set(v) {
                this.$emit("update:checkData", v);
            },
        },
        ordervisibles: {
            get() {
                return this.ordervisible;
            },
            set(v) {
                this.$emit("update:ordervisible", v);
            },
        },
    },
    methods:{
        refresh(){
            this.$emit('refresh')
        },
        //影像资料下载
        yxdownload(row){
            this.$emit('yxdownload',row)
        },
        //下载检查报告
        async download(row) {
            console.log(row);
            const params = {
                downloadUrl: row.downloadUrl,
                fileName: row.informationFileName,
            };
            this.$q
                .dialog({
                    title: "文件下载",
                    message: `你是否要下载${row.informationFileName}文件`,
                    ok: { label: "确定", color: "blue-6" },
                    cancel: { label: "取消", flat: true },
                })
                .onOk(async () => {
                // console.log(window.origin); //http://localhost:8080 本地地址
                const url = window.origin +
                "/order/download/file?downloadUrl=" +
                encodeURIComponent(params.downloadUrl) +
                "&fileName=" +
                encodeURIComponent(params.fileName);
                window.open(url);
                console.log(url);
                // let link = document.createElement("a") //创建一个a标签
                // link.href = url // 将a标签的 href 设置为bolb 下载地址
                // link.download = row.informationFileName // 将a标签设置为 可下载并设置了下载后的文件名称
                // link.click() // 点击下载
                // try{
                //     const { data: res } = await axios({
                //         method: 'GET',
                //         url: 'order/download/file/',
                //         params: params,
                //         responseType: 'blob', //将获取到的data数据变成blob形式
                //     })
                //     console.log(res);
                //     // let link = document.createElement("a") //创建一个a标签
                //     // link.href = window.URL.createObjectURL(res) // 将a标签的 href 设置为bolb 下载地址
                //     // link.download = row.informationFileName // 将a标签设置为 可下载并设置了下载后的文件名称
                //     // link.click() // 点击下载
                //     // let url = window.URL.createObjectURL(blob);
                //     // window.location.href = url;
                // }catch (e){
                //     console.log(e);
                // }
                
                });
        },
        // 下载发票信息
        async invoicedownload(row) {
            console.log(row);
            const params = {
                downloadUrl: row.downloadUrl,
                fileName: row.invoiceFileName,
            };
            this.$q
            .dialog({
                title: "文件下载",
                message: `你是否要下载${row.invoiceFileName}文件`,
                ok: { label: "确定", color: "blue-6" },
                cancel: { label: "取消", flat: true },
            })
            .onOk(async () => {
                const url = window.origin + 
                '/order/download/file?downloadUrl='+
                encodeURIComponent(params.downloadUrl)+
                '&fileName='
                +encodeURIComponent(params.fileName)

                window.open(url)
            });
        },
        downhide(){
            if (this.downdata.downjindu) {
                console.log("结束了");
                this.downdata.timerList.forEach((item, index) => {
                    window.clearInterval(item);
                })
                this.downdata.timerList = [];
                this.downdata.downjindu = 0;
            }
            this.downdata.downconfig =false
            this.downdata.downdataA = false
        },
    },
}
</script>
<style lang="less" scoped>
.my-card {
    width: 100%;
    margin-bottom: 0.5rem;
}
</style>
