export const required = {
    kong: () => {}, // 无表单验证

    // 登录页面规则
    accessCode: val => val && val.trim().length > 0 || '提取码不能为空',

    //新增用户
    username: val => val && val.length > 0 || '用户名不能为空',
    usernamelegth: val => val.length <= 20|| '用户名长度不能超过20位',
    nicklength: val => val.length <= 10 || "昵称最大长度为10",
    newpassword: val => val && val.length > 0 || '请输入密码',
    password: val => val.length >= 8 && val.length <= 20 || '密码最少为8到20位字符',
    phoneNumber: val => val && val.length > 0 || '手机号不能为空',
    phonelength: val => val.length == 11 || "手机号的长度为11位",
    // phonelength: (val) => {         //新增手机号规则
    //   if (val.trim().length === 0) {
    //     return () => {};
    //   } else {
    //     return (
    //       val.trim().length == 11 || "手机号长度必须为11位"
    //     );
    //   }
    // },
    verifyCode: val => val && val.trim().length > 0 || '验证码不能为空',
    // verifyCode: (val) => {
    //   if (val.trim().length === 0) {
    //     return () => {};
    //   } else {
    //     return (
    //       val.trim().length == 8  ||
    //       "验证码的长度为6位"
    //     );
    //   }
    // },
    editverifyCode: val => val.trim().length == 6 || "验证码长度为6位",
    // editverifyCode: (val) => {
    //   if (val.trim().length === 0) {
    //     return () => {};
    //   } else {
    //     return (
    //       val.trim().length == 6  ||
    //       "验证码的长度为6位"
    //     );
    //   }
    // },
    verifyCodelength: val => val && val.trim().length === 6 || '验证码错误',
    // 可以空着但是 填了就要大于8小于20 修改密码的规则
    defaults: (val) => {
        if (val.trim().length === 0) {
          return () => {};
        } else {
          return (
            (val.trim().length >= 8 &&
                val.trim().length <= 20) ||
            "长度在 8 到 20 个字符"
          );
        }
    },
    //新增角色
    roleName: val => val && val.trim().length > 0 || '角色名称不能为空',
    roleNamelength: val => val.trim().length <= 10 || '角色名称不可大于10个字符',
    roleDesc: val => val.trim().length <= 20 || '角色描述不可大于20个字符',

    //新增订单弹窗规则
    patientNameRules: (val) =>(val && val.trim().length > 0) || "患者姓名不能为空", // 患者名称规则
    patientNameLength: (val) =>(val && val.trim().length <= 32) || "患者姓名不能超过32个字", // 患者名称规则
    doctorNameRules: (val) =>(val && val.length > 0) || "医生姓名不能为空", // 医生姓名
    doctorNameLength: (val) =>(val==null || val.trim().length <= 10 ) || "医生姓名不能超过10个字", // 患者名称规则
    hospNumberLength: (val) =>(val==null || val.trim().length <= 64 ) || "住院号不能超过64个字", // 住院号规则

    selectc: (val) =>(val && val.cityName != null) || '城市不能为空', // 城市规则
    selectp: (val) =>(val && val.provinceName != null) || '省份不能为空', // 省份规则
    selecth: (val) =>(val && val.hospName != null) || '医院不能为空', // 医院规则
    selectd: (val) =>(val && val.departName != null) || '科室不能为空', // 科室规则
    selectds: (val) =>(val && val.length > 0) || '科室不能为空', // 科室多选规则
    comboselect: (val) =>(val && val.comboName != null) || '套餐不能为空', // 套餐规则
    orderTypemodel: (val) =>(val && val.value != null) || '资料类型不能为空', // 资料类型规则
    ispaymodel: (val) =>(val && val.value != null) || '代理商收款状态不能为空', // 代理商付款状态规则


    surgeryDate: (val) =>(val && val.length > 0) || "手术时间不能为空",  // 手术时间规则
    // orderAmt:(val) =>val && val.trim().length > 0 || "订单金额不能为空", // 订单金额规则
    orderlimit: val=>( val || val==0) && val>=0 && val<= 999999.99 || '订单金额在0~999999.99之间', //订单金额规则
    diagnoseDesclength: val =>( val==null || val.trim().length <=40) || '诊断说明长度不能超过40个字符',//诊断说明规则
    orderDesclength: val => (val==null || val.trim().length <=40) || '订单备注信息长度不能超过40个字符',//订单备注信息规则

    // 查看弹窗规则
    payStatusmodel:(val) =>(val && val.value != null) || '支付状态不能为空', // 支付状态规则
    orderStatusmodel:(val) =>(val && val.value != null) || '订单状态不能为空', // 订单状态规则

    // 订单审核
    reject: (val) => (val.trim().length <=80) || '驳回理由不能超过80个字符',//驳回理由规则
    checkDesclength: val => (val==null || val.trim().length <=100) || '备注信息长度不能超过100个字符',//订单备注信息规则


    // 医院管理新增规则
    addhospname: val => val && val.trim().length > 0 || '医院名称不能为空',

    // 发布管理
    apkNameRule: (val) =>(val && val.value != null) || '应用名称不能为空', // 应用名称规则
    osTypeRule: (val) =>(val && val.value != null) || '系统类型不能为空', // 系统类型规则
    publishTypeRule: (val) =>(val && val.value != null) || '发布类型不能为空', // 发布类型规则
    apkVersionRule: val => val && val.length > 0 || '版本号不能为空', // 版本号规则
    publishCommentRule: val => val.trim().length <= 128 || '发布说明最大长度为128', // 发布说明规则
    
    //商户管理
    mntNameRule: val => val && val.length > 0 || '商户名称不能为空', // 商户名称规则
    mntNameLengthRule: val => val.length < 64 || '商户名称最大长度为64', // 商户名称长度规则
    mntIdRule: val => val && val.length > 0 || '商户号不能为空', // 商户号规则
    mntIdLengthRule: val => val.length < 64 || '商户号最大长度为64', // 商户号长度规则
    mntKeyRule: val => val && val.length > 0 || '商户秘钥不能为空', // 商户秘钥规则
    mntKeyLengthRule: val => val.length < 128 || '商户秘钥最大长度为128', // 商户秘钥长度规则

    // 优惠码管理
    discountNum: val => val && val.length > 0 || '优惠码数量不能为空', // 优惠码数量
    discountNumLength: val=> val && val>=1 && val<= 100 || '优惠码数量必须在0到100之间', //优惠码数量范围
    discountPercent: val => val && val.length > 0 || '折扣力度不能为空', // 折扣力度
    discountPercentLength: val=> val && val>0 && val < 1 || '折扣力度必须在0到1之间', //折扣力度范围

    bingRules: (val) =>(val && val.trim().length > 0) || "病变类型不能为空",
    bingLength: (val) =>( val.trim().length <= 10) || "病变类型不能超过10个字",
    erpatientNameLength: (val) =>(val&& val.trim().length <= 10) || "患者姓名不能为空且不能超过10个字"
}