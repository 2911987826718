<template>
    <div>
        <q-dialog v-model="alerts" persistent >
            <q-card class="my-card">
                <q-card-section>
                <div class="text-h6">添加用户信息</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <q-form ref="myForm" greedy>
                        <q-input :dense="true" class="bing" outlined v-model="nametexts" label="患者姓名" :rules='[required.erpatientNameLength]'/>
                        <q-input :dense="true" outlined v-model="bingtexts" label="病变类型" :rules='[required.bingLength]'/>
                    </q-form>
 
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="取消" color="primary" v-close-popup @click="quxiao"/>
                    <q-btn flat label="生成二维码" color="primary" @click='shengcheng' />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>
<script>
import  {required}  from '@/api/components/required.js' // 表单验证规则
export default {
        props: {
            alert:{
                type: Boolean,
                required: true,
            },
            nametext:{
                type: String,
                required: true,
            },
            bingtext:{
                type: String,
                required: true,
            },
        },
        data() {
            return {
                required
            }
        },
        created(){
        },
        computed:{
            alerts:{
                get(){
                    return this.alert
                },
                set(v){
                    this.$emit('update:alert',v)
                }
            },
            nametexts:{
                get(){
                    return this.nametext
                },
                set(v){
                    this.$emit('update:nametext',v)
                }
            },
            bingtexts:{
                get(){
                    return this.bingtext
                },
                set(v){
                    this.$emit('update:bingtext',v)
                }
            },
        },
        methods:{
            quxiao(){
                this.nametexts =''
                this.bingtexts =''
                this.$refs.myForm.resetValidation()
            },
            shengcheng(){
                this.$emit('shengcheng')
            }
        },
}
</script>
<style lang="less" scoped>
.my-card{
    width: 25rem;
}
</style>
