<template>
    <el-popover
        placement="bottom"
        width="170"
        trigger="click">
            <q-checkbox 
                v-for="(box,index) in items" :key="index"
                v-model="box[box.prop]" 
                :label="box.value" 
                color="cyan" 
                :style='box.style'
            />
        <el-button icon="el-icon-s-grid" size='medium' slot="reference" style="padding: 5px 10px;border: none;">
            <i class="el-icon-caret-bottom"></i>
        </el-button>
    </el-popover>
</template>
<script>
export default {
    props:{
        item:{
            type: Array,
            required: true,
        }
    },
    data() {
        return {
        }
    },
    created(){
    },
    computed:{
        items:{
            get(){
                return this.item
            },
            set(v){
                this.$emit('update:item',v)
            }
        }
    },
    methods:{
    },
}
</script>
<style lang="less" scoped>

</style>
