<template>
    <div>
        <q-dialog v-model="alerts" @show='handleQrcodeToDataUrl' @hide='guanbi'>
            <q-card>
                <!-- <q-card-section class="row items-center q-pb-none cha">
                    <q-space />
                    <q-btn icon="close" flat round dense v-close-popup />
                </q-card-section> -->

                <q-card-section class="q-pt-none cha">
                    <div id="meQrcode">
                        <div class="qrcode_box">
                        <img
                            class="qrcode_canvas"
                            id="qrcode_canvas"
                            ref="qrcode_canvas"
                            alt="二维码本图"
                        />
                        <img
                            v-if="qrLogo"
                            class="qrcode_logo"
                            ref="qrcode_logo"
                            :src="qrLogo"
                            alt="公司logo"
                        />
                        <canvas
                            width="320"
                            height="400"
                            class="canvas"
                            ref="canvas"
                        ></canvas>
                        </div>
                    </div>
                </q-card-section>
    <!-- 
                <q-card-actions align="right">
                    <q-btn  label="确定" color="primary" v-close-popup />
                </q-card-actions> -->
            </q-card>
        </q-dialog>
    </div>
</template>
<script>
import QRCode from "qrcode";
// import logo from "@/assets/logo.png";
import {beijing} from "@/assets/beijing.js";    
import {logo} from "@/assets/logo.js";    
import {icondui} from "@/assets/icondui.js";

export default {
    props: {
        alert:{
            type: Boolean,
            required: true,
        },
        imgurl:{
            type: String,
            required: true,
        },
        qrSize: {  //二维码宽高
            type: Number,
            default: 320
            // default: 450
        },
        qrText: {
            type: String,
        },
        qrText2: {
           type: String,
        },
        qrLogo: {
            type: String,
            default: ''
        },
        qrLogoSize: {
            type: Number,
            default: 40
        },
        qrTextSize: {
            type: Number,
            default: 26
        },
        qrUrl: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            // uul: require("../../assets/shbj.jpg"),
            // logouul: require("../../assets/avatar.png"),
        }
    },
    created(){
    },
    mounted(){
        // this.handleQrcodeToDataUrl();
        // this.chart = this.$echarts.init(document.getElementById("line1"), null, { renderer: "svg" });
    },
    update(){
        this.handleQrcodeToDataUrl();
    },
    computed:{
        alerts:{
            get(){
                return this.alert
            },
            set(v){
                this.$emit('update:alert',v)
            }
        },
    },
    methods:{
        /**
     * @argument qrUrl        二维码内容
     * @argument qrSize       二维码大小
     * @argument qrText       二维码中间显示文字
     * @argument qrTextSize   二维码中间显示文字大小(默认16px)
     * @argument qrLogo       二维码中间显示图片
     * @argument qrLogoSize   二维码中间显示图片大小(默认为80)
     */
      handleQrcodeToDataUrl() {
        let qrcode_canvas = this.$refs.qrcode_canvas;
        let qrcode_logo = this.$refs.qrcode_logo;
        let canvas = this.$refs.canvas;
       
        const that = this;
        // console.log(that.imgurl);
        // console.log(this.$refs.qrcode_canvas);
        var logoimg = new Image;
        logoimg.src = logo;
        
        var img = new Image;
        img.src = beijing;

        var iconimg = new Image;
        iconimg.src = icondui;

        console.log(this.imgurl);
        QRCode.toDataURL(
        // this.imgurl,{width: 260, height: 260},
        this.imgurl,{width: 250, height: 250},
        (err, url) => {
            qrcode_canvas.src = url;
            // 画二维码里的logo// 在canvas里进行拼接
            let ctx = canvas.getContext("2d");

            // let dpr = window.devicePixelRatio; // 假设dpr为2
            // console.log(dpr);
            // // 获取css的宽高
            // let { width: cssWidth, height: cssHeight } = canvas.getBoundingClientRect();
            // console.log(cssWidth); 
            // console.log(cssHeight);

            // canvas.width = dpr * cssWidth;
            // canvas.height = dpr * cssHeight;

            // ctx.scale(dpr,dpr);
            setTimeout(() => {
                //设置背景图
                // ctx.drawImage(img, 0, 0,380,500);
                ctx.drawImage(img, 0, 0,320,400);

                //二维码距离左边框距离
                // let erweimaLength = (380-260) / 2 
                let erweimaLength = (320-250) / 2 
                //获取二维码图片 把二维放到画布上
                // ctx.drawImage(qrcode_canvas, erweimaLength,65, 260, 260);
                ctx.drawImage(qrcode_canvas, erweimaLength,60, 250, 250);
                
                // if (that.qrLogo) {
                    ctx.fillStyle = "#fff";
                    ctx.beginPath();
                    let logoPosition = (that.qrSize - that.qrLogoSize) / 2; //logo相对于canvas居中定位
                    // let h = that.qrLogoSize + 10; //圆角高 10为基数(logo四周白色背景为10/2) 中间挖空的白色高
                    let h = 50; 
                    // let w = that.qrLogoSize + 10; //圆角宽 中间挖空的白色宽
                    let w = 50; 
                    // 手机端盒子宽320为140 电脑宽位380为170;
                    // let x = 165;
                    let x = 140;
                    // let y = logoPosition 手机155  电脑160
                    // let y = 160;
                    let y = 155;
                    console.log(x); //logo 左侧距离 125
                    console.log(y); //logo 上边距离 125
                    console.log(h);//圆角高 10为基数(logo四周白色背景为10/2) 50
                    console.log(w);//圆角宽 50
                    let r = 5; //圆角半径
                    ctx.moveTo(x, y);
                    // ctx.moveTo(125, 125);
                    ctx.arcTo(x + w , y , x + w, y + h, r);
                    // ctx.arcTo(175, 125, 175 ,175, 5);
                    ctx.arcTo(x + w, y + h, x, y + h, r);
                    // ctx.arcTo(175, 175, 125, 175 , 5);
                    ctx.arcTo(x, y + h, x, y, r);
                    // ctx.arcTo(125 , 175, 125 , 125 , 5);
                    ctx.arcTo(x, y, x + w, y, r);
                    // ctx.arcTo(125, 125, 175 , 125 , 5);
                    ctx.closePath();
                    ctx.fill();
                    // console.log(logoimg);
                    //渲染logo x轴 手机320/145  电脑380/175
                    //渲染logo y轴 手机160  电脑165
                    // ctx.drawImage(
                    //     logoimg,
                    //     170,
                    //     165,
                    //     40,
                    //     40,
                    // );
                    ctx.drawImage(logoimg,145, 160,40,40);
                // }
                if (that.qrText) {
                    
                    //设置字体
                    // ctx.font = "bold " + that.qrTextSize + "px Arial";
                    // ctx.font =  that.qrTextSize + "px Arial";
                    

                    ctx.textBaseline = "top"; //设置绘制文本时的文本基线。

                    //ctx.fillText  (内容,左边框距离,上边框距离, 最大长度)
                    // 医学影像三维重建标题 字体
                    ctx.fillStyle="#fff";
                    ctx.font = "22px Arial";
                    let tit = ctx.measureText('医学影像三维重建').width; //医学影像三维重建 宽度
                    // let titlength = ((380 - tit) / 2) //医学影像三维重建与左边框的距离
                    let titlength = ((320 - tit) / 2) //医学影像三维重建与左边框的距离
                    ctx.fillText('医学影像三维重建', titlength, 20,300);  
                
                    // middle
                    // ctx.textBaseline = "top"; //设置绘制文本时的文本基线。
                    //患者姓名和病变类型字体
                    
                    ctx.font = "14px Arial";
                    let tw = ctx.measureText(that.qrText).width; //文字真实宽度
                    let tw2 = ctx.measureText(that.qrText2).width; //文字真实宽度
                    
                    // let length = ((380 - tw) / 2) - 25 //患者姓名与左边框 距离 - icon图标的大小和间距 26 + 20
                    let length = ((320 - tw) / 2) - 25 
                    // let length2 = (380 - tw2) / 2   //病变类型与左边看距离
                    let length2 = (320 - tw2) / 2  
                    let iconleft = length + tw + 30 //icon 图标与左边框的距离   

                    
                    //转义 Unicode 设置icon图标
                    // ctx.beginPath();
                    // ctx.font="16px iconfont";  // 将这里的Arial 设置为自己图标库的名称
                    // var icon = eval('("'+'&#xe8bd;'.replace('&#x','\\u').replace(';','')+'")')
                    // ctx.fillStyle= "#00ff62"
                    // ctx.fillText(icon,iconleft,400,300);
                    // ctx.drawImage(iconimg, iconleft,358, 16, 16);
                    ctx.drawImage(iconimg, iconleft,358, 16, 16);


                    // 设置患者姓名和病变类型 字体位置 颜色
                    ctx.fillStyle="#fff";
                    // if(this.qrText2 == ''){
                    //     ctx.fillText(that.qrText, length, 423,320);
                    // }else{
                    //     ctx.fillText(that.qrText, length, 423,320);

                    //     ctx.fillText(that.qrText2, length2, 360,320);
                    // }

                    if(this.qrText2 == ''){
                        ctx.fillText(that.qrText, length, 360,320);
                    }else{
                        ctx.fillText(that.qrText, length, 360,320);

                        ctx.fillText(that.qrText2, length2, 325,320);
                    }
                    
                    /**
                     * 绘制一个矩形框框柱 患者姓名和对号 
                     * (患者姓名离左边的宽度-10 , 患者姓名离上边框高度 , 文字宽度 + (两侧10 + 患者姓名和对号距离 30 + 对号宽度 16) , 高度25)
                     */
                    ctx.strokeStyle= "#adadad"
                    // ctx.strokeRect(length -10,417,tw + 66, 25)
                    ctx.strokeRect(length -10,353,tw + 70, 25)

                   
                    //抗锯齿
                    ctx.imageSmoothingEnabled = false
                    ctx.webkitImageSmoothingEnabled = false;
                    ctx.msImageSmoothingEnabled = false;
                    ctx.imageSmoothingEnabled = false;
                
                }
                
      
                canvas.style.display = "none";
                qrcode_canvas.src = canvas.toDataURL();
                qrcode_canvas.style.display = "inline-block";
            }, 30);
        }
        ); 
      },
      guanbi(){}
    },
}
</script>
<style lang="less" scoped>
// .qrcode_box{
// }
.qrcode_box,
#meQrcode {
  display: inline-block;
//   height: 500px;
}
.canvas{
  background-color: rgba(255, 255, 255,1) !important;
}
.qrcode_box img {
  display: none;
}
.cha{
    padding: 0;
    line-height:0;
}
@font-face {
  font-family: 'iconfont';  /* Project id 2504782 */
  src: url('//at.alicdn.com/t/font_2504782_a2gvkw6sqw.woff2?t=1649665260938') format('woff2'),
       url('//at.alicdn.com/t/font_2504782_a2gvkw6sqw.woff?t=1649665260938') format('woff'),
       url('//at.alicdn.com/t/font_2504782_a2gvkw6sqw.ttf?t=1649665260938') format('truetype');
}
</style>
