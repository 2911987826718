<template>
  <div>
    <!--
        option-label="value"
    -->
    <q-select
        outlined
        :disable='disable'
        v-model="getmodel"
        :options="options"
        class="operation"
        :label="label"
        color="teal"
        :dense="true"
        lazy-rules
        :rules="[
            required.kong
        ]"
        options-selected-class="text-deep-orange"
    >
        <!-- <template v-slot:option="scope">
            <q-item
                v-bind="scope.itemProps"
                v-on="scope.itemEvents"
            >
                <q-item-section>
                    <q-item-label>{{
                        scope.opt.value
                    }}</q-item-label>
                </q-item-section>
            </q-item>
        </template> -->
    </q-select>
  </div>
</template>

<script>
import  {required}  from '@/api/components/required.js' // 表单验证规则

export default {
    props:{
        options:{
            type: Array,
            required: true
        },
        model:{
            type: [String,Object],
            required: true
        },
        label:{
            type: String,
            required: true
        },
        disable:{
            type: Boolean
        }
    },
    created(){},
    data() {
        return {
            required
        };
    },
    components:{},
    computed:{
        getmodel:{
            get(){
                return this.model;
            },
            set(v){
                this.$emit('update:model',v)
            }
        }
    },
    watch:{
    },
    methods: {
        provincechange(){
            this.$emit('provincechange')
        },
    },
};
</script>

<style lang='less' scoped>

</style>
