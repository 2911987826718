<template>
  <div>
    <q-form ref="myForm" greedy class="add">
      <div class='maxgao'>
          <q-card flat bordered class="my-card add">
            <q-card-section>
            <div class="row justify-between">
                <div style="padding: 0 5px;" class="col-16 col-md-3">
                    <!-- 订单号 -->
                    <q-input :disable='true' class="add" :dense='true' outlined  v-model="editAllData.orderId" label="订单号*" lazy-rules :rules='[required.kong]' />
                    <!-- 城市选择 -->
                    <selectc @citychange='citychange'  class='add' :disable='editDatas.jichudisable' :options='checkAlllists.city' :model.sync='editDatas.cityoptions' :label='"请选择城市*"'/>
                    <!-- 主管医生下拉框 doctorselect -->
                    <doctorselect  class='add' :disable='editDatas.jichudisable' :options='checkAlllists.attendingDoctor' :model.sync='editDatas.attendingDoctorOptions' :label='"请选择主管医师"'/>
                    <!-- 支付状态 -->
                    <selectq class='add' :disable='true' :options='payStatus' :model.sync='editDatas.payStatusoptions' :label='"支付状态*"' :rule='required.payStatusmodel'/>
                    <!-- 折扣力度 -->
                    <q-input class="add" :disable="true" :dense='true' outlined  v-model="editDatas.discountPercent" label="折扣力度" lazy-rules :rules='[required.kong]'/>
                </div>
                
                <div style="padding: 0 5px;" class="col-16 col-md-3">
                    <!-- 患者姓名 -->
                    <q-input :disable='editDatas.jichudisable' class="add" :dense='true' outlined  v-model="editAllDatas.patientName" label="患者姓名*" lazy-rules :rules='[required.patientNameRules,required.patientNameLength]' />
                    <!-- 医院选择 -->
                    <selecth  @hospchange='hospchange'  class='add' :disable='editDatas.jichudisable' :options='checkAlllists.hosp' :model.sync='editDatas.hospoptions' :label='"请选择医院*"'/>
                    <!-- 代理商是否付款 -->
                    <selectq class='add' :disable='editDatas.disable' :options='isPay' :model.sync='editDatas.ispayoptions' :label='"代理商是否收款"' :rule='required.kong'/>
                    <!-- 住院号 -->
                    <q-input class="add" :disable='editDatas.disable' :dense='true' outlined  v-model="editAllDatas.hospNumber" label="住院号" lazy-rules :rules='[required.kong,required.hospNumberLength]'/>
                    <!-- 加急金额 -->
                    <q-input class="add" :disable="true" :dense='true' outlined  v-model="editDatas.urgentAmt" label="加急金额" lazy-rules :rules='[required.kong]'/>
                </div>

                <div style="padding: 0 5px;" class="col-16 col-md-3">
                    <!-- 医生姓名 -->
                    <q-input :disable='editDatas.disable' class="add" :dense='true' outlined  v-model="editAllDatas.doctorName" label="医生姓名" lazy-rules
                        :rules='editDatas.hospoptions.hospName == "莲和" ? [required.doctorNameLength] : [required.doctorNameRules,required.doctorNameLength]'
                    />
                    <!-- 科室选择 -->
                    <selectd @departchange='departchange' class='add' :disable='editDatas.jichudisable' :options='checkAlllists.depart' :model.sync='editDatas.departoptions' :label='"请选择科室*"'/>
                    <!-- 订单类型 -->
                    <selectq class='add' :disable='editDatas.jichudisable' :options='orderType' :model.sync='editDatas.orderTypeoptions' :label='"订单类型"' :rule='required.kong'
                        @bian='orderTypeChange' @clear='orderTypeChange'
                    />
                    <!-- 订单金额 -->
                    <q-input :disable='true' class="add" type="number" :dense='true' outlined
                        :value="editDatas.orderTypeoptions.key==0 ? 0 : editDatas.orderAmt"
                        label="订单金额*" lazy-rules :rules='[required.orderlimit]'
                    />
                    <!-- 回款金额 -->
                    <q-input v-if="editDatas.returnedAmtDisable" class="add" :disable="true" :dense='true' outlined
                        :value="editDatas.orderTypeoptions.key==0 ? 0 : editDatas.orderReturnedAmt" label="回款金额"
                        lazy-rules :rules='[required.kong]'
                    />
                </div>

                <div style="padding: 0 5px;" class="col-16 col-md-3">
                    <!-- 省份选择 -->
                    <selectp @provincechange='provincechange'  class='add' :disable='editDatas.jichudisable' :options='checkAlllists.province' :model.sync='editDatas.provinceoptions' :label='"请选择省份*"' />
                    <!-- 套餐选择 -->
                    <comboselect @combochange='combochange'  class='add' :disable='editDatas.jichudisable' :options='checkAlllists.combo' :model.sync='editDatas.combooptions' :label='"请选择套餐*"'/>
                    <!-- 手术时间 -->
                    <surgeryDate class='add' :disable='editDatas.disable' :model.sync='editDatas.surgeryDate'/>
                    <!-- 优惠码 -->
                    <q-input class="add" :disable="editDatas.discountCodeDisable" :dense='true' outlined  v-model="editDatas.discountCode" label="优惠码" lazy-rules :rules='[required.kong]' @blur='discountCodeChange'/>
                </div>
                <!-- <div class="col-5"></div>
                <div class="col-5"></div> -->
                <!-- 驳回理由 -->
                <div class='add' style="padding: 0 5px;">
                    <q-input
                        :disable='true'
                        :dense='true' 
                        outlined  
                        autogrow
                        type="textarea"
                        v-model="editDatas.reject" 
                        label="驳回原因" 
                        lazy-rules 
                        :rules="[required.kong]"
                    />
                </div>
                <div class='add' style="padding: 0 5px;">
                    <!-- 诊断说明 -->
                    <q-input
                        :disable='editDatas.disable'
                        class='add'
                        :dense='true' 
                        outlined  
                        autogrow
                        type="textarea"
                        v-model="editAllData.diagnoseDesc" 
                        label="诊断说明" 
                        lazy-rules 
                        :rules="[required.editdiagnoseDesclength,required.diagnoseDesclength]"
                    />
                </div>
                <div class='add' style="padding: 0 5px;">
                    <!-- 订单备注信息 -->
                    <q-input
                        :disable='editDatas.disable' 
                        :dense='true' 
                        outlined  
                        autogrow
                        type="textarea"
                        v-model="editAllData.orderDesc" 
                        label="订单备注信息" 
                        lazy-rules 
                        :rules="[required.orderDesclength]"
                    />
                </div>
                
                
            </div>
            </q-card-section>
        </q-card>
        <q-card flat bordered class="my-card add">
            <q-card-section>
                <!-- <tabulationdate
                    :orderList='editDatas.checkReportFilesList'
                    :orderid="editAllDatas.orderId"
                    :prop='"informationFileName"'
                    :label='"检查报告"'
                /> -->
                <el-table :data="editDatas.checkReportFilesList" style="width: 100%">
                    <el-table-column label="检查报告" prop="informationFileName" show-overflow-tooltip></el-table-column>
                    <el-table-column label="" prop="createTime"></el-table-column>
                    <!-- 空白占位格 -->
                    <el-table-column align='center' label="" width='130'>
                 
                    </el-table-column>
                    <el-table-column align="right" width='150'>
                    <template slot="header">
                        <el-upload
                            class="upload-demo upload"
                            ref="checkupload"
                            action=""
                            :limit='1'
                            :on-change='checkReportFileschange'
                            :file-list="checkReportFilesList"
                            :auto-upload="false"
                            >
                            <el-button slot="trigger" size="small" id="Cbtn" type="primary" >上传检查报告</el-button>
                        </el-upload>
                        <el-button slot="reference" size="small" type="primary" @click='CdcmReport'>上传检查报告</el-button>
                    </template>
                    <template slot-scope="scope">
                        <!-- 检查报告下载 -->
                        <el-button
                        size="mini"
                        type="primary"
                        icon="iconfont icon-xiazai"
                        @click="download(scope.row)"
                        ></el-button>
                        <el-button size="mini" type="danger" icon="iconfont icon-ashbin" @click="delfilecheckReport(scope.row)"></el-button>
                    </template>
                    </el-table-column>
                </el-table>
            </q-card-section>
        </q-card>
        <q-card flat bordered class="my-card add">
            <q-card-section>
                <!-- <tabulationdate
                :orderList='editDatas.dcmReportFilesList'
                :orderid="editAllDatas.orderId"
                :prop='"informationFileName"'
                :label='"影像资料"'
                ></tabulationdate> -->
                <el-table 
                    v-loading="ordervisible"
                    element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(55,55,55,0.2)"
                    :data="editDatas.dcmReportFilesList" 
                    style="width: 100%"
                >
                    <el-table-column label="影像资料" prop="informationFileName" show-overflow-tooltip></el-table-column>
                    <el-table-column label="" prop="createTime"></el-table-column>
                    <el-table-column align='center' label="资料状态" width='130'>
                        <template slot="header"> 
                            资料状态<i style="margin-left: 12px;" class="el-icon-refresh-left" @click="refresh"></i>
                        </template>
                        <template slot-scope="scope">
                            <q-chip v-if="scope.row.processFlg != 1" square :color="scope.row.processFlg==0?'primary':'red'" text-color="white">
                                {{scope.row.processFlg==0?'处理中':'处理失败'}}
                                <q-tooltip v-if="scope.row.errMsg">
                                    {{scope.row.errMsg}}
                                </q-tooltip>
                            </q-chip>
                        </template>
                    </el-table-column>
                    <el-table-column align="right" width='150'>
                        <template slot="header">
                            <el-upload
                            class="upload-demo upload"
                            ref="dcmReportupload"
                            action=""
                            accept=".zip,.rar,.7z"
                            style='display:none'
                            :show-file-list="true"
                            :on-change='dcmReportFileschange'
                            :file-list="dcmReportFilesList"
                            :auto-upload="false"
                            >
                                <el-button slot="trigger" size="small" id='Dbtn' type="primary">选取影像资料</el-button>
                            </el-upload>
                            <el-button :disabled='editDatas.dcmReportdisable' size="small" type="primary" @click='DdcmReport'>上传影像资料</el-button>
                        </template>
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" icon="iconfont icon-xiazai" @click="yxdownload(scope.row)"></el-button>
                            <el-button :disabled='editDatas.dcmReportdisable' size="mini" type="danger" icon="iconfont icon-ashbin" @click="delfiledcmReport(scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </q-card-section>
        </q-card>

      </div>
      <div class="addbtn">
          <q-btn
            class="checkbtn"
            label="取消"
            color="blue-6"
            @click="quxiao"
          />
          <q-btn
            :disable='editDatas.disable'
            class="checkbtn"
            label="保存"
            color="blue-6"
            @click="savefile"
          />
          <q-btn
            :disable='editDatas.baocundisable'  
            class="checkbtn"
            label="保存并提交"
            color="blue-6"
            @click="commits"
          />
      </div>
    </q-form>
    <!-- 上传文件提示区 -->
    <q-dialog v-model="alert" persistent>
      <q-card style="width: 400px;">
        <q-card-section>
          <div class="wenjian">文件: {{fileName}}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-linear-progress size="25px" :value="checkprogress" color="primary">
            <div class="absolute-full flex flex-center">
              <q-badge color="white" text-color="primary" :label="checkprogressLabel" />
            </div>
          </q-linear-progress>
        </q-card-section>
        <q-card-section class="q-pt-none">
          
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- 普通影像资料下载链接生成弹窗 -->
    <yxDown
        ref='yxDown'
        :downdata.sync='downdata'
        @downhide='downhide'
    />
  </div>
</template>

<script>
import { Loading } from 'element-ui';

import { informationdownload } from '@/api/components/informationdownload.js' //影像资料下载
import yxDown from '@/components/Down/informationDown.vue' // 影像链接生成弹窗组件

import { getprovince,getcity,gethosplist,getdepart,getcombo,getattendingDoctor } from '@/api/components/getCity.js'; // 获取城市接口
import { getreturnedAmt } from '@/api/components/checkcitys.js'; // 获取城市接口

import { mapState } from 'vuex' //vuex获取数据
import axios from 'axios'

import { deletefile,orderEdit,uploadingfile,informationCheck,orderCommit } from '@/api/Order.js'; // 用户订单新增接口
import { discountCodeCheck } from '@/api/Discount.js'

import { dateFormat } from '@/utils/time.js'; // 用户订单新增接口

// import tabulationdate from '@/components/Order/tabulationdate' // 资料表格组件
import surgeryDate from '@/components/Order/surgeryDate' // 时间选择组件
import selectq from '@/components/Order/select' // 普通下拉框组件
import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件
import selecth from '@/components/Order/select/hospselect.vue' // 医院下拉框组件
import selectd from '@/components/Order/select/departsselect.vue' // 科室下拉框组件
import comboselect from '@/components/Order/select/comboselect.vue' // 套餐下拉框组件
import doctorselect from '@/components/Order/select/doctorselect.vue' // 主管医生下拉框组件

import  {required}  from '@/api/components/required.js' // 表单验证规则
import { makeDebounce } from "@/utils/debounce.js";  
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        checkAlllist: { //所有城市省份医院信息
            type: Object,
            required: true,
        },
        editAllData:{ //修改订单的数据
            type: Object,
            required: true,
        },
        editData:{ //所选的城市
            type: Object,
            required: true,
        }
    },
    created(){
    },
    data() {
        return {
            ordervisible: false,

            text:'',
            required,
            // disable: this.editAllData.orderStatus==0 || this.editAllData.orderCheck.checkStatus == 2 ? false : true , //控制是否禁用
            
            visible: false, //控制弹出框
            checkReportFilesList:[],
            dcmReportFilesList:[], //影像资料

            alert: false, // 控制上传文件提示
            checkprogress: 0, //进度条
            checkprogressLabel: '', //进度百分比
            fileName: '', // 文件名

            options:{
                target:'document.body',
                text:'下载链接获取中,如果时间较长请耐心等待',
            },


            downdata:{
                downconfig: false,
                downcheckprogress: 0, //进度条
                downcheckprogressLabel: '', //进度百分比
                downfileName: '', // 文件名
                downjindu: null, //用于储存定时器
                downstepName:'', //压缩步骤
                timerList:[],
                downdataA:true, //
                downdataB: true,
            },
        };
    },
    components:{
        selectq, // 普通下拉组件
        selectp, // 省份下拉框组件,
        selectc, //城市下拉框
        selecth, // 医院下拉框
        selectd, // 科室下拉框
        comboselect, //套餐下拉框
        doctorselect, //主管医生下拉框
        surgeryDate, // 手术时间
        yxDown, //影像链接生成弹窗组件
        // tabulationdate // 资料表格
    },
    computed:{
        ...mapState([
            'orderType',//订单类型
            'payStatus', // 支付状态
            'isPay', //代理商
            'payStatus', //支付状态
        ]),
        checkAlllists:{
            get(){
                return this.checkAlllist
            },
            set(v){
                this.$emit('update:checkAlllist',v)
            }
        },
        editAllDatas:{
            get(){
                return this.editAllData
            },
            set(v){
                this.$emit('update:editAllData',v)
            }
        },
        editDatas:{
            get(){
                return this.editData
            },
            set(v){
                this.$emit('update:editData',v)
            }
        }
    },
    watch:{
    },
    methods: {
 
        // 校验优惠码
        async verificationCode(code){
            const { data: res } = await discountCodeCheck({
                // discountCode: this.editDatas.discountCode
                discountCode: code
            })
            console.log(res);
            // 优惠码校验通过
            if(res.code==20000){
                this.editDatas.discountCodeAdopt = true
                this.editDatas.discountPercent = res.data.discount.discountPercent //储存折扣力度
                //如果套餐金额已经有了就直接修改套餐金额
                if(this.editDatas.combooptions.comboAmt){
                    // this.editDatas.orderAmt = (this.editDatas.combooptions.comboAmt * (this.editDatas.discountPercent *100)) /100
                    this.editDatas.orderAmt = ((this.editDatas.combooptions.comboAmt * (this.editDatas.discountPercent *100)) /100)
                }
            }
        },
        // 优惠码失去焦点时  ndaaarvj   hcaaa6r4  rcaaaza0  ncaaav65   icaaaw9x
        async discountCodeChange(){
            console.log('验证优惠码');
            // this.editDatas.discountCode = ''
            if(this.editDatas.discountCode.length){
             
                if(this.editDatas.discountCode == this.editAllDatas.discountCode){
                    // 不管试用还是付费都可以通过优惠码
                    this.editDatas.discountCodeAdopt = true
                    
                    if(this.editDatas.orderTypeoptions.key == 0){// 试用订单
                        // if(this.editDatas.orderTypeoptions.key != 0){
                        this.editDatas.discountPercent = this.editAllDatas.discountPercent //储存折扣力度
                        this.editDatas.discountCode = this.editAllDatas.discountCode
                        // 修改套餐金额
                        if(this.editDatas.combooptions.comboAmt){
                            this.editDatas.orderAmt =((this.editDatas.combooptions.comboAmt * (this.editDatas.discountPercent *100)) /100) || 0
                        }
                        // }
                    }else{ //付费
                        console.log('zheli ');
                        this.verificationCode(this.editDatas.discountCode)
                        this.editDatas.discountCodeAdopt = true
                    }
                    
                }else{
                    this.verificationCode(this.editDatas.discountCode)
                }
            }else{
                this.editDatas.discountCodeAdopt = true
                this.editDatas.discountPercent = '' //验证失败清空折扣力度
                // 如果有套餐金额就 还原正常的套餐金额
                if(this.editDatas.combooptions.comboAmt){
                    this.editDatas.orderAmt=this.editDatas.combooptions.comboAmt
                }
            }
        },
        //刷新影像资料
        refresh:makeDebounce(async function(row) {
            console.log('刷新',this.editAllDatas);
            this.ordervisible = true;
            setTimeout(async() =>{
                const {data:res} = await informationCheck({orderId:this.editAllDatas.orderId})
                console.log(res);
                if(res.code===20000){
                    notifys('positive',res.message)
                    this.editDatas.dcmReportFilesList =[] // 清空影像资料
                    // console.log(this.checkData.reject);
                    if(res.data.informationList){
                        res.data.informationList.map(item=>{
                            if(item.informationType == 1){
                                this.editDatas.dcmReportFilesList.push(item)
                            }
                        })
                    }
                    this.ordervisible = false
                }else{
                    notifys('negative',res.message)
                    this.ordervisible = false
                }
            },1000)
           
        },300),
        //获取用户信息
        async getlist(){
            const {data:res} = await informationCheck({orderId:this.editAllDatas.orderId})
            this.editDatas.checkReportFilesList=[] //检查报告列表信息
            this.editDatas.dcmReportFilesList=[] //影像资料列表信息
            if(res.data.informationList){
                res.data.informationList.map(item=>{
                if(item.informationType == 0){
                    this.editDatas.checkReportFilesList.push(item)
                } else{
                    this.editDatas.dcmReportFilesList.push(item)
                }
                })
            }
        },
        // 省份变化时获取城市列表
        provincechange(){
            this.checkAlllists.city=[] //清空城市
            this.editDatas.cityoptions =''
            this.checkAlllists.hosp=[] //清空医院
            this.editDatas.hospoptions =''
            this.checkAlllists.depart=[] //清空科室
            this.editDatas.departoptions =''
            this.checkAlllists.combo=[] //清空套餐
            this.editDatas.combooptions =''
            getcity(this.checkAlllists.city,this.editDatas.provinceoptions.id)
        },
        // 城市变化时获取医院列表
        citychange(){
            // console.log(this.getadddata.provinceId.id);只有这样才能获取到id值
            this.checkAlllists.hosp=[] //清空医院
            this.editDatas.hospoptions =''
            this.checkAlllists.depart=[] //清空科室
            this.editDatas.departoptions =''
            this.checkAlllists.combo=[] //清空套餐
            this.editDatas.combooptions =''
            gethosplist(this.checkAlllists.hosp,this.editDatas.cityoptions.id)
        },
        // 医院发生变化时
        hospchange(){
            this.checkAlllists.depart=[] //清空科室
            this.editDatas.departoptions =''
            this.checkAlllists.combo=[] //清空套餐
            this.editDatas.combooptions =''
            getdepart(this.checkAlllists.depart,this.editDatas.hospoptions.id)
            getcombo(this.checkAlllists.combo,this.editDatas.hospoptions.id,this.editDatas.cityoptions.id)
        },
        // 科室改变时
        departchange(){
            this.checkAlllists.attendingDoctor=[] //清空主管医师
            this.editDatas.attendingDoctorOptions =''

            getattendingDoctor(this.checkAlllists.attendingDoctor,this.editDatas.departoptions.id) // 获取主管医生列表
        },
        // 套餐变化时
        combochange(){
            this.editDatas.orderAmt =''
            this.editDatas.orderAmt=this.editDatas.combooptions.comboAmt
            if(this.editDatas.discountPercent){
                this.editDatas.orderAmt = (this.editDatas.combooptions.comboAmt * (this.editDatas.discountPercent *100)) /100
            }
        },
        // 检查报告文件发生改变时
        checkReportFileschange(file, fileList){
            console.log(file.raw);
            this.checkReportFilesList.push(file.raw)
            console.log(this.checkReportFilesList);
            this.checkReportsave()
            fileList=[]
            this.checkReportFilesList=[]
        },
        //影像资料改变时
        dcmReportFileschange(file, fileList){
            let fileName = file.name;
            let uid = file.uid
            let pos = fileName.lastIndexOf(".");
            let lastName = fileName.substring(pos, fileName.length);
            // if (lastName.toLowerCase() !== ".zip" && lastName.toLowerCase() !== ".rar") {
            //     notifys('negative',"文件必须为.rar .zip 类型")
            //     // this.resetCompressData()
            //     for(var i = 0;i<fileList.length;i++) {
            //         if(fileList[i].uid == uid) {
            //             fileList.splice(i,1)
            //         }
            //     }
            //     return;
            // }
            // 限制上传文件的大小
            const isLt = file.size / 1024/ 1024 / 1024 / 2 <= 1;
            if (!isLt) {
                notifys('negative','上传文件不得大于2G!')
                for(var i = 0;i<fileList.length;i++) {
                    if(fileList[i].uid == uid) {
                        fileList.splice(i,1)
                    }
                }
                return
            }

            let Array1=''
            let Array2=''
            let Array3=''
            let Array4=''
            let Array5=''
            let Array6=''
            let Array7=''
            let Array8=''
            // 限制文件类型
            var reader = new FileReader();
            reader.readAsArrayBuffer(file.raw);
            reader.onload =  (da) =>{ //有一个异步回调
                console.log(da.target.result);

                let yy = new Uint8Array(da.target.result) //后端需要的二进制格式
                Array1 = yy[0]
                Array2 = yy[1]
                Array3 = yy[2]
                Array4 = yy[3]
                Array5 = yy[4]
                Array6 = yy[5]
                Array7 = yy[6]
                Array8 = yy[7]

                if(Array1==55 && Array2==122 && Array3==188 && Array4==175 && Array5==39 && Array6==28 && Array7==0 && Array8==3){
                console.log('7z文件');
                }else if(Array1==80 && Array2==75 && Array3==3 && Array4==4){
                console.log('zip文件');
                }else if(Array1==82 && Array2==97 && Array3==114 && Array4==33 && Array5==26 && Array6==7 && Array7==0){
                console.log('rar4文件');
                }else if(Array1==82 && Array2==97 && Array3==114 && Array4==33 && Array5==26 && Array6==7 && Array7==1 && Array8==0){
                console.log('rar5文件');
                }else{
                for(var i = 0;i<fileList.length;i++) {
                    if(fileList[i].uid == uid) {
                    fileList.splice(i,1)
                    }
                }
                notifys('negative',`文件本身必须为.rar .zip .7z 类型`)
                return
                }
                console.log('Array1',Array1);
                console.log('Array2',Array2);
                console.log('Array3',Array3);
                console.log('Array4',Array4);
                console.log('Array5',Array5);
                console.log('Array6',Array6);
                console.log('Array7',Array7);
                console.log('Array8',Array8);

                this.dcmReportFilesList.push(file.raw)
                this.dcmReport()
                fileList=[]
                this.dcmReportFilesList=[]
            }
        },
        
        // 检查报告上传
        async checkReportsave(){
            this.alert = true
            for (let i = 0; i < this.checkReportFilesList.length; i++){
                try{
                    this.checkprogress=0  //清空进度条
                    this.checkprogressLabel='' // 清空进度百分比
                    this.fileName = this.checkReportFilesList[i].name //获取文件名字
                    const filedata = new FormData()
                    filedata.append('informationFile',this.checkReportFilesList[i])
                    filedata.append('fileType',0)
                    filedata.append('orderId',this.editAllData.orderId)
                    // console.log(filedata);
                    const { data: res } = await uploadingfile(filedata, event=>{
                        // event.loaded
                        // event.total
                        this.checkprogress = event.loaded/event.total
                        this.checkprogressLabel=`文件已上传${((event.loaded/event.total)*100).toFixed(2)}%`
                    })
                    console.log(res);
                    if(res.code ==20000){
                        this.$refs.checkupload.clearFiles()
                        this.getlist()
                        notifys('positive',res.message)
                    }else{
                        this.$refs.checkupload.clearFiles()
                        this.getlist()
                        notifys('negative',`文件${this.checkReportFilesList[i].name},${res.message}`)
                    }
                }catch(e){
                    this.alert = false
                    notifys('negative', `文件传输失败,请检查网络`)
                }
            }
            this.alert = false
        },
        // checkhide(){  //检查报告弹出框关闭时
        //     this.checkReportFilesList=[]
        //     this.$refs['checkupload'].clearFiles();
        // },
        // dcmReporthied(){ //影像资料弹出框关闭时
        //     this.dcmReportFilesList=[]
        //     this.$refs['dcmReportupload'].clearFiles();
        // },
        CdcmReport(){  //点击上传检查报告时
            var C=document.getElementById("Cbtn");
            C.click();
        },
        DdcmReport(){ // 点击 上传影像资料时
            console.log(this.editDatas.dcmReportdisable);
            var D = document.getElementById("Dbtn");
            D.click();
        },
        // 影像资料上传 dcmReportFilesList
        async dcmReport(){
            // console.log(this.dcmReportFilesList);
            this.alert = true
            try {
                for (let i = 0; i < this.dcmReportFilesList.length; i++){
                    this.checkprogress=0
                    this.checkprogressLabel=''
                    this.fileName = this.dcmReportFilesList[i].name
                    const filedata = new FormData()
                    filedata.append('informationFile',this.dcmReportFilesList[i])
                    filedata.append('fileType',1)
                    filedata.append('orderId',this.editAllData.orderId)
                    // console.log(filedata);
                    const { data: res } = await uploadingfile(filedata,event=>{
                        console.log(event);
                        this.checkprogress = event.loaded/event.total
                        this.checkprogressLabel=`文件已上传${((event.loaded/event.total)*100).toFixed(2)}%`
                    })
                    console.log(res);
                    if(res.code ==20000){
                        this.getlist()
                        this.visible = false
                        notifys('positive',res.message)
                    }else{
                        this.$refs.checkupload.clearFiles()
                        this.getlist()
                        this.alert = false
                        notifys('negative',`文件${this.dcmReportFilesList[i].name},${res.message}`)
                    }
                }
                this.alert = false
            }catch (e) {
                this.alert = false
                alert('文件上传失败请检查网络')
                
            }
        },
        //下载检查报告
        async download(row){
            console.log(row);
            const params={
                downloadUrl: row.downloadUrl,
                fileName: row.informationFileName
            }
            this.$q.dialog({
                title: '文件下载',
                message: `你是否要下载${row.informationFileName}文件?`,
                ok: { label: '确定',color: 'blue-6'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                const url= window.origin + 
                '/order/download/file?downloadUrl='+
                encodeURIComponent(row.downloadUrl)+
                '&fileName='+
                encodeURIComponent(row.informationFileName)

                window.open(url)
            })
        },
        //影像资料下载
        yxdownload:makeDebounce(async function(row) {
            informationdownload(
                row, //所下载的id信息
                this.downdata
            )
        },500),
  
        //下载生成进度条关闭时
        downhide(){
            if (this.downdata.downjindu) {
                console.log("结束了");
                this.downdata.timerList.forEach((item, index) => {
                    window.clearInterval(item);
                })
                this.downdata.timerList = [];
                this.downdata.downjindu = 0;
            }
            this.downdata.downconfig =false
            this.downdata.downdataA = false
        },

        // 删除检查报告资料
        async delfilecheckReport(row){
            console.log(row);
            this.$q.dialog({
                title: '删除',
                message: `你确定要删除此 ${row.informationFileName} 检查报告吗?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                try{
                    const { data: res } = await deletefile({id:row.id})
                    console.log(res);
                    if(res.code===20000){
                        notifys('positive',res.message)
                        this.getlist()
                    
                    }else{
                        notifys('negative',res.message)
                    }
                }catch (e){
                    console.log(e);
                }
            })
        },
        // 删除影像资料
        async delfiledcmReport(row){
            console.log(row);
            this.$q.dialog({
                title: '删除',
                message: `你确定要删除此 ${row.informationFileName} 影像资料吗?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                try{
                    const { data: res } = await deletefile({id:row.id})
                    console.log(res);
                    if(res.code===20000){
                        notifys('positive',res.message)
                        this.getlist()
                        
                    }else{
                        notifys('negative',res.message)
                    }
                }catch (e){
                    console.log(e);
                }
            })
        },
        // 点击取消时
        quxiao(){
            this.$emit('closeeditdialog')
        },
        // 点击保存
        savefile(){
            const orderTypes= this.editDatas.orderTypeoptions.key != undefined ? this.editDatas.orderTypeoptions.key : ''
            const isPays= this.editDatas.ispayoptions.key != undefined ? this.editDatas.ispayoptions.key : ''
            if(!this.editDatas.discountCodeAdopt){
                notifys('negative',`优惠码不可用请重新输入或清空优惠码`)
                return
            }       
            this.$refs.myForm.validate().then( async outcome =>{
                if(outcome){
                    const { data: res } = await orderEdit({
                        patientName:this.editAllDatas.patientName,
                        provinceId:this.editDatas.provinceoptions.id,
                        cityId:this.editDatas.cityoptions.id,
                        hospId:this.editDatas.hospoptions.id,
                        comboId:this.editDatas.combooptions.id,
                        departId:this.editDatas.departoptions.id,
                        attendingDoctor:this.editDatas.attendingDoctorOptions, //主管医生
                        doctorName:this.editAllDatas.doctorName,
                        diagnoseDesc:this.editAllDatas.diagnoseDesc,
                        orderDesc: this.editAllDatas.orderDesc,
                        orderType: orderTypes,
                        isPay: isPays,
                        surgeryDate: this.editDatas.surgeryDate,
                        orderAmt: this.editDatas.orderAmt,
                        orderId: this.editAllDatas.orderId,
                        hospNumber: this.editAllDatas.hospNumber,
                        discountCode: this.editDatas.discountCode,
                    })
                    console.log(res);
                    if(res.code===20000){
                        this.$emit('closeeditdialog') //关闭对话框
                        this.$emit('getOrderList')  //更新接口
                        notifys('positive',res.message)
                    }else{
                        notifys('negative',res.message)
                    }
                }
            })
        },
        commits(){ // 提交审核
            this.$q.dialog({
                title: '提交审核',
                message: `你确定要保存并提交此订单吗?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(() => {
                try{
                    const orderTypes= this.editDatas.orderTypeoptions.key != undefined ? this.editDatas.orderTypeoptions.key : ''
                    const isPays= this.editDatas.ispayoptions.key != undefined ? this.editDatas.ispayoptions.key : ''

                    if(!this.editDatas.discountCodeAdopt){
                        notifys('negative',`优惠码不可用请重新输入或清空优惠码`)
                        return
                    }    
                    this.$refs.myForm.validate().then( async outcome =>{
                        if(outcome){
                            var allstatus = this.editAllDatas.orderStatus==0 || (this.editAllDatas.reconstructionVo.informationStatus == 2 && this.editAllDatas.orderCheck.checkStatus == 2) || this.editAllDatas.orderCheck.checkStatus == 2//控制是否禁用
                            if(allstatus){
                                const { data: res } = await orderEdit({
                                    patientName:this.editAllDatas.patientName,
                                    provinceId:this.editDatas.provinceoptions.id,
                                    cityId:this.editDatas.cityoptions.id,
                                    hospId:this.editDatas.hospoptions.id,
                                    comboId:this.editDatas.combooptions.id,
                                    departId:this.editDatas.departoptions.id,
                                    attendingDoctor:this.editDatas.attendingDoctorOptions, //主管医生
                                    doctorName:this.editAllDatas.doctorName,
                                    diagnoseDesc:this.editAllDatas.diagnoseDesc,
                                    orderDesc: this.editAllDatas.orderDesc,
                                    orderType: orderTypes,
                                    isPay: isPays,
                                    surgeryDate: this.editDatas.surgeryDate,
                                    orderAmt: this.editDatas.orderAmt,
                                    orderId: this.editAllDatas.orderId,
                                    hospNumber: this.editAllDatas.hospNumber,
                                    discountCode: this.editDatas.discountCode,
                                })
                                console.log(res);
                                if(res.code===20000){
                                    const { data: rows } = await orderCommit({orderId: this.editAllData.orderId})
                                    console.log(rows);
                                    if(rows.code===20000){
                                        notifys('positive',res.message)
                                    }else{
                                        notifys('negative',res.message)
                                    }
                                    this.$emit('closeeditdialog') //关闭对话框
                                    this.$emit('getOrderList')  //更新接口
                                    notifys('positive',res.message)
                                }else{
                                    notifys('negative',res.message)
                                }
                            }else{
                                const { data: rows } = await orderCommit({orderId: this.editAllData.orderId})
                                console.log(rows);
                                if(rows.code===20000){
                                    notifys('positive',res.message)
                                }else{
                                    notifys('negative',res.message)
                                }
                                this.$emit('closeeditdialog') //关闭对话框
                                this.$emit('getOrderList')  //更新接口
                            }
                        }
                    })
                    
                }catch (e){
                    console.log(e);
                }
            })
      
        },

        // 订单类型改变时
        async orderTypeChange(){
            console.log('改变了');
            this.editDatas.orderAmt = this.editDatas.combooptions.comboAmt

            this.discountCodeChange()

            // 如果是切换成付费订单就需要获取一下回款金额
            if(this.editDatas.orderTypeoptions.key != 0){

                this. editDatas.orderReturnedAmt = await getreturnedAmt(
                    this.editDatas.cityoptions.id,
                    this.editDatas.hospoptions.id,
                    this.editDatas.combooptions.id
                )
            }
        }
    },
};
</script>

<style lang='less' scoped>
.my-card{
  width: 100%;
  margin-bottom: .5rem;
}
.add {
    width: 100%;
    margin-left: 0;
    margin-top: 0.1875rem;
}
.addbtn {
    margin: .9375rem 0 0 0;
    display: flex;
    justify-content: space-between;
    .checkbtn{
        width: 8.125rem;
    }
}
.q-th{
    height: 0;
}
.popover{
    width: 15.625rem;
    height: 5rem;
}
.upload{
    display: none;
}
.wenjian{
  font-size: 17px;
  font-weight: bold;
}
.maxgao{
    max-height: 50vh;
    overflow: auto;
}
/deep/.el-dialog__body{
    padding: 0 10px 10px !important;
}
/deep/.el-dialog__header {
    padding: 20px 20px 0 !important;
}
</style>
