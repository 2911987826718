import { createAPI } from "../utils/request"

export const discountLists = data => createAPI('base/discount/list', 'get', data) //优惠码列表

export const discountGenerator = data => createAPI(`base/discount/generator?num=${data.num}&discountPercent=${data.discountPercent}`, 'post', data) //优惠码生成

export const discountSave = data => createAPI(`base/discount/save/${data.id}?discountPercent=${data.discountPercent}`, 'post', data) //修改优惠码

export const discountData = data => createAPI(`base/discount/get/${data.id}`, 'get', data) //优惠码详细

export const discountDelete = data => createAPI(`base/discount/delete/${data.id}`, 'get', data) //优惠码删除

export const discountCodeCheck = data => createAPI(`base/discount/check/${data.discountCode}`, 'get', data) //优惠码校验




