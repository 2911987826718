import { createAPI } from "../utils/request"

export const informationCheckList = data => createAPI('order/informationCheck/list', 'get', data) //订单审核列表

export const informationCheckclaim = data => createAPI('order/informationCheck/claim', 'get', data) //批量领取任务

export const informationCheckagree = data => createAPI('order/informationCheck/agree', 'get', data) //批量审核通过



export const informationCheckreject = data => createAPI(`order/informationCheck/reject/${data.id}`, 'get', data) //驳回

export const CheckdownloadList = data => createAPI(`order/informationCheck/downloadList/${data.orderId}`, 'get', data) //查询上传资料清单


export const orderCheck = data => createAPI(`order/informationCheck/get/${data.orderId}`, 'get', data) //资料审核订单详细

// v 1.5.0
export const informationCheckStart = data => createAPI(`order/informationCheck/check/start`, 'get', data) //启动检测

export const informationCheckProgress = data => createAPI(`order/informationCheck/check/progress`, 'get', data) //检测进度

export const informationCheckResult = data => createAPI(`order/informationCheck/check/result`, 'get', data) //查询检测结果

export const informationCheckLink = data => createAPI(`order/userOrder/information/link`, 'get', data) //患者影像资料下载链接生成

export const informationLink = data => createAPI(`order/informationCheck/check/link`, 'get', data) //生成检测结果下载链接

export const informationDelete = data => createAPI(`order/informationCheck/check/delete`, 'get', data) //生成检测结果删除

export const informationResign = data => createAPI(`order/informationCheck/resign/${data.id}`, 'get', data) //资料审核订单详细








