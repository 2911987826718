import { createAPI } from "../utils/request"

export const orderList = data => createAPI('order/userOrder/list', 'get', data) //订单列表

export const orderAdd = data => createAPI('order/userOrder/create', 'post', data) //下单接口 新增订单

export const orderEdit = data => createAPI('order/userOrder/save/', 'post', data) // 编辑订单

export const orderDelete = data => createAPI(`order/userOrder/delete/${data.orderId}`, 'get', data) //订单删除

export const downloads = data => createAPI('order/download/file/', 'get', data) //文件下载

export const deletefile = data => createAPI(`order/userOrder/information/delete/${data.id}`, 'get', data) //文件删除

export const orderCheck = data => createAPI(`order/userOrder/get/${data.id}`, 'get', data) //订单详细

export const orderCommit = data => createAPI(`order/userOrder/commit/${data.orderId}`, 'get', data) //订单提交审核

export const checkInformationResult = data => createAPI(`order/userOrder/information/result/${data.orderId}`, 'get', data) //查看重建结果

export const informationCheck = data => createAPI(`order/informationCheck/downloadList/${data.orderId}`, 'get', data) //查询上传资料清单

export const uploadingfile = (data, onUploadProgress) => createAPI(`order/userOrder/information/save/${data.get("orderId")}`, 'post', data, {onUploadProgress}) //上传文件