<template>
    <div class="row justify-between">
        <!-- 第一列 -->
        <div style="padding: 0 5px;" class="col-16 col-md-3">
            <!-- 订单号 -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkAllData.orderId"
                label="订单号*"
                lazy-rules
                :rules="[required.kong]"
            />
            <!-- 城市选择 -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkAllData.cityName"
                label="城市*"
                lazy-rules
                :rules="[required.kong]"
            />
            <!-- 主管医生下拉框 doctorselect -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkAllData.attendingDoctor"
                label="主管医师"
                lazy-rules
                :rules="[required.kong]"
            />
            <!-- 订单状态 -->
            <selectq
                class="add"
                :disable="true"
                :options="orderStatus"
                :model.sync="checkDatas.orderStatusoptions"
                :label="'订单状态'"
                :rule="required.orderStatusmodel"
            />
            <!-- 住院号 -->
            <q-input 
                class="add" 
                :disable='true' 
                :dense='true' 
                outlined  
                v-model="checkAllData.hospNumber" 
                label="住院号" 
                lazy-rules 
                :rules='[required.kong]'
            />
            <!-- 加急金额 -->
            <q-input class="add" :disable="true" :dense='true' outlined  v-model="checkDatas.urgentAmt" label="加急金额" lazy-rules :rules='[required.kong]'/>

        </div>
        <!-- 第二列 -->
        <div style="padding: 0 5px;" class="col-16 col-md-3">
            <!-- 患者姓名 -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkAllData.patientName"
                label="患者姓名*"
                lazy-rules
                :rules="[
                    required.patientNameRules,
                    required.patientNameLength,
                ]"
            />
            <!-- 医院选择 -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkAllData.hospName"
                label="医院*"
                lazy-rules
                :rules="[required.kong]"
            />
            <!-- 支付状态 -->
            <selectq
                class="add"
                :disable="true"
                :options="payStatus"
                :model.sync="checkDatas.payStatusoptions"
                :label="'支付状态'"
                :rule="required.payStatusmodel"
            />
            <!-- 下单时间 -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkAllData.createTime"
                label="下单时间*"
                lazy-rules
                :rules="[required.kong]"
            />
            <!-- 创建人 -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkAllData.createUser"
                label="创建人*"
                lazy-rules
                :rules="[required.kong]"
            />
            <!-- 支付流水号 -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkDatas.payId"
                label="支付流水号*"
                lazy-rules
                :rules="[required.kong]"
            />
        </div>
        <!-- 第三例 -->
        <div style="padding: 0 5px;" class="col-16 col-md-3">
            <!-- 医生姓名 -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkAllData.doctorName"
                label="医生姓名*"
                lazy-rules
                :rules="[required.doctorNameRules]"
            />
                <!-- 科室选择 -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkAllData.departName"
                label="科室*"
                lazy-rules
                :rules="[required.kong]"
            />
            <!-- 代理商是否付款 -->
            <selectq
                class="add"
                :disable="true"
                :options="isPay"
                :model.sync="checkDatas.ispayoptions"
                :label="'代理商是否收款'"
                :rule="required.orderStatusmodel"
            />
            <!-- 手术时间 -->
            <surgeryDate
                class="add"
                :disable="true"
                :model.sync="checkDatas.surgeryDate"
            />
            <!-- 优惠码 -->
            <q-input class="add" :disable="true" :dense='true' outlined  v-model="checkDatas.discountCode" label="优惠码" lazy-rules :rules='[required.kong]'/>
            <!-- 回款金额 -->
            <q-input v-if="checkDatas.returnedAmtDisable" class="add" :disable="true" :dense='true' outlined  v-model="checkDatas.orderReturnedAmt" label="回款金额" lazy-rules :rules='[required.kong]'/>
        </div>
        <!-- 第四列 -->
        <div style="padding: 0 5px;" class="col-16 col-md-3">
            <!-- 省份选择 -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkAllData.provinceName"
                label="省份*"
                lazy-rules
                :rules="[required.kong]"
            />
            <!-- 套餐选择 -->
            <q-input
                :disable="true"
                class="add"
                :dense="true"
                outlined
                v-model="checkAllData.comboName"
                label="套餐*"
                lazy-rules
                :rules="[required.kong]"
            />
            <!-- 订单类型 -->
            <selectq
                class="add"
                :disable="true"
                :options="orderType"
                :model.sync="checkDatas.orderTypeoptions"
                :label="'订单类型'"
                :rule="required.orderTypemodel"
            />
            <!-- 订单金额 -->
            <q-input
                :disable="true"
                class="add"
                type="number"
                :dense="true"
                outlined
                v-model="checkAllData.orderAmt"
                label="订单金额*"
                lazy-rules
                :rules="[required.orderlimit]"
            />
            <!-- 折扣力度 -->
            <q-input class="add" :disable="true" :dense='true' outlined  v-model="checkDatas.discountPercent" label="折扣力度" lazy-rules :rules='[required.kong]'/>

        </div>


        <!-- <div class="col-5"></div>
        <div class="col-5"></div> -->
        <div class='add' style="padding: 0 5px;">
            <!-- 驳回原因 -->
            <q-input
                :disable="true"
                :dense="true"
                outlined
                autogrow
                type="textarea"
                v-model="checkDatas.reject"
                label="驳回原因"
                lazy-rules
                :rules="[required.kong]"
            />
        </div>
        <div class='add' style="padding: 0 5px;">
            <!-- 诊断说明 -->
            <q-input
                :disable="true"
                :dense="true"
                outlined
                autogrow
                type="textarea"
                v-model="checkAllData.diagnoseDesc"
                label="诊断说明"
                lazy-rules
                :rules="[required.kong]"
            />
        </div>
        <div class='add' style="padding: 0 5px;">
            <!-- 订单备注信息 -->
            <q-input
                :disable="true"
                :dense="true"
                outlined
                autogrow
                type="textarea"
                v-model="checkAllData.orderDesc"
                label="订单备注信息"
                lazy-rules
                :rules="[required.kong]"
            />
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex"; //vuex获取数据

import surgeryDate from "@/components/Order/surgeryDate"; // 时间选择组件
import selectq from "@/components/Order/select"; // 普通下拉框组件
import selectp from "@/components/Order/select/provinceselect.vue"; // 省份下拉框组件
import selectc from "@/components/Order/select/cityselect.vue"; // 城市下拉框组件
import selecth from "@/components/Order/select/hospselect.vue"; // 医院下拉框组件
import selectd from "@/components/Order/select/departsselect.vue"; // 科室下拉框组件
import comboselect from "@/components/Order/select/comboselect.vue"; // 套餐下拉框组件
import doctorselect from '@/components/Order/select/doctorselect.vue' // 主管医生下拉框组件

import { required } from "@/api/components/required.js"; // 表单验证规则

export default {
    props: {
        checkAllData: {
            //修改订单的数据
            type: Object,
            required: true,
        },
        checkData: {
            //所选的城市
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            required,
        }
    },
    created(){
    },
    components:{
        selectq, // 普通下拉组件
        selectp, // 省份下拉框组件,
        selectc, //城市下拉框
        selecth, // 医院下拉框
        selectd, // 科室下拉框
        comboselect, //套餐下拉框
        doctorselect, //主管医生下拉框
        surgeryDate, // 手术时间
    },
    computed:{
        ...mapState([
            "orderStatus", //订单状态
            "orderType", //订单类型
            "payStatus", // 支付状态
            "isPay", // 代理商是否收款
        ]),
        checkAllDatas: {
            get() {
                return this.checkAllData;
            },
            set(v) {
                this.$emit("update:checkAllData", v);
            },
        },
        checkDatas: {
            get() {
                return this.checkData;
            },
            set(v) {
                this.$emit("update:checkData", v);
            },
        },
    },
    methods:{
    },
}
</script>
<style lang="less" scoped>
.add {
    width: 100%;
    margin-left: 0;
    margin-top: 0.1875rem;
}
</style>
