import { provinceList,cityList,hospList,departList,comboList,attendingDoctorList } from '@/api/City.js'; // 获取城市接口
  // 获取省份信息
export const getprovince=async (province)=>{
  const { data:res } = await provinceList()
  // console.log(res);
  res.data.provinces.map((row=>{
    province.push(row)
  }))
  return province
}
  // 获取城市
export const getcity=async(city,provinceid)=>{
  const { data:res } = await cityList({provinceId:provinceid})
  console.log(res);
  res.data.cities.map((row=>{
    city.push(row)
  }))
  return city
}
  //获取医院信息
export const gethosplist=async(hosplist,cityid,departName)=>{
  const { data:res } = await hospList({cityId:cityid,departName:departName})
  console.log(res);
  if(res.data.hosps){
    res.data.hosps.map((row=>{
      hosplist.push(row)
    }))
  }
  return hosplist
}
  // 获取科室信息
export const getdepart=async(departs,hospid)=>{
  const { data:res } = await departList({hospId:hospid})
  console.log(res);
  if(res.data.departs){
    res.data.departs.map((row=>{
      departs.push(row)
    }))
  }
  return departs
}

//根据城市医院获取套餐
export const getcombo=async(combo,hospid,cityId)=>{
  const { data:res } = await comboList({
    hospId:hospid,
    cityId:cityId
  })
  console.log(res);
  if(res.data.combos){
    res.data.combos.map((row=>{
      combo.push(row)
    }))
  }
  return combo
}


//根据科室获取主治医师
export const getattendingDoctor=async(doctor,departId)=>{
  const { data:res } = await attendingDoctorList({
    departId:departId
  })
  console.log(res);
  if(res.data.doctors){
    res.data.doctors.map((row=>{
      doctor.push(row)
    }))
  }
  return doctor
}
