<template>
    <div class="row">
      <div class="col-4">
          <div class="column" style="height: 100%;">
                <div class="col-10">
                    <q-input :disable='true' :dense='true' outlined v-model="resultlist.accessCode" label="提取码" />
                    <el-button size='small' plain class='extractbtn' @click='fetchextractcode'>复制提取码</el-button>
                    <el-button size='small' plain class='extractbtn' @click='onAccessClick'>点击直接提取</el-button>
                </div>
                <div class="col">
                    <el-button :disabled='resultlist.downloadUrl?false: true' size='small' plain class='extractbtn' @click="download">下载重建报告</el-button>
                </div>
            </div>
      </div>
      <div class="col-8 rightbox">
            <el-button size='small' plain @click='addyourdata'>添加患者信息</el-button>
            <div class="imgbox">
                <img
                    :src="imgsrc"
                    />
            </div>
      </div>

        <addPatientInformation
            ref="addPatientInformation"
            :alert.sync='addInformationalert'
            :nametext.sync='nametext'
            :bingtext.sync='bingtext'
            @shengcheng='shengcheng'
        />

        <!-- 生成用户信息二维码 -->
        <generateQRcode
            :alert.sync='generateQRcode'
            :imgurl='imgurl'
            :qrText='qrText'
            :qrText2='qrText2'
        />
    </div>
</template>

<script>
import axios from 'axios'
import QRCode from 'qrcode' 
import addPatientInformation from '@/components/Order/AddPatientInformation.vue' 
import generateQRcode from '@/components/Order/GenerateQRcode.vue' 
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        resultlist:{
            type: Object,
            required: true
        },
        imgsrc:{
            type: String,
            required: true
        },
        imgurl:{
            type: String,
            required: true
        },
        huanzhe:{
            type: String,
            required: true
        }
    },
    created(){
    },
    data() {
        return {
            addInformationalert: false,
            nametext:'',
            bingtext:'',
            
            generateQRcode: false,
            qrText:'',
            qrText2:''
        };
    },
    components:{
        addPatientInformation,
        generateQRcode
    },
    computed:{
        huanzhes:{
            get(){
                return this.huanzhe
            },
            set(v){
                this.$emit('update:huanzhe',v)
            }
        },
},
    watch:{
    },
    methods: { //window.open("http://aiforcare.com/viewer?code=" + code) ${this.resultlist.accessCode}
        // getimg(){
        //     // // let text = 'http://aiforcare.com/viewer?code=7GvjLj';
        //     QRCode.toDataURL(`http://aiforcare.com/viewer?code=${this.resultlist.accessCode}`, (err, url)=> {
        //     this.img = url;
        //     })
        // },
        //点击直接提取
        onAccessClick(){
            console.log(this.resultlist.accessCode);
            window.open("/#/viewer?code=" + this.resultlist.accessCode);
            // window.open(`http://aiforcare.com/viewer?code=${this.resultlist.accessCode}`)
        },
        // 点击下载重建报告
        async download(){
            const params = {
                downloadUrl: this.resultlist.downloadUrl,
                fileName: this.resultlist.reconstructionReport
            }
            this.$q.dialog({
                title: '重建报告下载',
                message: `你是否要下载此报告`,
                ok: { label: '确定',color: 'blue-6'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                const url = window.origin + 
                '/order/download/file?downloadUrl='+
                encodeURIComponent(params.downloadUrl)+
                '&fileName='+
                encodeURIComponent(params.fileName)

                window.open(url)
            })
        },
        // 点击复制提取码
        fetchextractcode(){
            var input = document.createElement("input");   // js创建一个input输入框
            input.value = this.resultlist.accessCode;  // 将需要复制的文本赋值到创建的input输入框中
            document.body.appendChild(input);    // 将输入框暂时创建到实例里面
            input.select();   // 选中输入框中的内容
            document.execCommand("Copy");   // 执行复制操作
            document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
            if(this.resultlist.accessCode){
                notifys('positive','复制成功')
            }
        },
        //添加用户信息
        addyourdata(){
            this.addInformationalert = true
            this.nametext = this.huanzhes
        },
        //点击生成二维码
        shengcheng(){
            this.$refs.addPatientInformation.$refs.myForm.validate().then( async outcome =>{
                if(outcome){
                    this.qrText = `${this.nametext}`
                    this.qrText2 = `${this.bingtext}`
                    this.addInformationalert = false
                    this.generateQRcode = true
                    console.log(111);
                    console.log(this.nametext);
                    this.nametext=''
                    this.bingtext=''
                }
            })
        }
    },
};
</script>

<style lang='less' scoped>
.extractbtn{
    width: 100%;
    margin-top: .625rem;
    margin-left: 0;
}
.rightbox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // padding: 0 0 0 .3125rem;
    .imgbox{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 12.5rem;
            height: 12.5rem;
        }
    }
}
</style>
