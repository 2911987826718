<template>
  <div class='surgeryDatebox'>
        <!-- <q-input outlined label='手术时间' :disable='disable' readonly  v-model="getmodel" lazy-rules :dense="true" :rules="[required.kong]">
            <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy transition-show="scale" transition-hide="scale">
                        <q-date @input='startDate' v-model="getmodel" mask="YYYY-MM-DD HH:mm"  :locale="myLocale" :options="optionsFn">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="关闭" color="primary" flat />
                        </div>
                        </q-date>
                    </q-popup-proxy>
                </q-icon>
            </template>
            <template v-slot:append>
                <q-icon name="access_time" class="cursor-pointer">
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                    <q-time v-model="getmodel" mask="YYYY-MM-DD HH:mm" format24h>
                    <div class="row items-center justify-end">
                        <q-btn v-close-popup label="关闭" color="primary" flat />
                    </div>
                    </q-time>
                </q-popup-proxy>
                </q-icon>
            </template>
        </q-input> -->
        <el-date-picker
            :class="disable?'onlyReadStyle':'dataStyle'"
            :readonly='disable'
            value-format='yyyy-MM-dd HH:mm:ss'
            v-model="getmodel"
            type="datetime"
            placeholder="请选择手术时间"
            @change='change'
        >
        </el-date-picker>
  </div>
</template>

<script>
import  {required}  from '@/api/components/required.js' // 表单验证规则

export default {
    props:{
        model:{
            type: [String,Date]
        },
        disable:{
            type: Boolean
        }
    },
    created(){},
    data() {
        return {
            myLocale: {
                /* starting with Sunday */
                days: '星期一_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
                daysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
                months: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
                monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
                firstDayOfWeek: 1
            },
            required
        };
    },
    components:{
    },
    computed:{
        getmodel:{
            get(){
                return this.model;
            },
            set(v){
                this.$emit('update:model',v)
            }
        }
    },
    watch:{
    },
    methods: {
        startDate(){
            this.$emit('detEndDate')
        },
        change(){
            console.log(this.getmodel);
            console.log(this.optionsFn(this.getmodel));
        },
        optionsFn (date) {
            const data = new Date();
            var y = data.getFullYear();  
            var m = data.getMonth() + 1;  
            m = m < 10 ? ('0' + m) : m;  
            var d = data.getDate();  
            d = d < 10 ? ('0' + d) : d;  
            var h = data.getHours();  
            h=h < 10 ? ('0' + h) : h;  
            const time = y + '/' + m + '/' + d
            return date >= time
        },
    },
};
</script>

<style lang='less' scoped>
.surgeryDatebox{
    padding-bottom: 20px;
}
.dataStyle{
    width: 100%;
    /deep/.el-input__inner {
        border: 1px solid #a9aaaf;
        &::placeholder {
            color: #606266;
        }
        &::-webkit-input-placeholder {
            /* WebKit browsers 适配谷歌 */
            color: #606266;
        }
        &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 适配火狐 */
            color: #606266;
        }
        &::-moz-placeholder {
            /* Mozilla Firefox 19+ 适配火狐 */
            color: #606266;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10+  适配ie*/
            color: #606266;
        }
    }
    /deep/.el-input__suffix{
        color: #606266;
    }
    /deep/.el-input__prefix{
        color: #606266;
    }
}

.onlyReadStyle{
    width: 100%;
    /deep/.el-input__inner {
        border: 1px solid #a9aaaf;
        color: #a9aaaf;
        &::placeholder {
            color: #a9aaaf;
        }
        &::-webkit-input-placeholder {
            /* WebKit browsers 适配谷歌 */
            color: #a9aaaf;
        }
        &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 适配火狐 */
            color: #a9aaaf;
        }
        &::-moz-placeholder {
            /* Mozilla Firefox 19+ 适配火狐 */
            color: #a9aaaf;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10+  适配ie*/
            color: #a9aaaf;
        }
    }
}
</style>
