import { provinceList,cityList,hospList,departList,comboList,attendingDoctorList,returnedAmt } from '@/api/City.js'; // 获取城市接口
// 获取省份信息
export const getprovince=async ()=>{
  const { data:res } = await provinceList()
  // console.log(res);
  return res.data.provinces
}
// 获取城市
export const getcity=async(provinceid)=>{
  const { data:res } = await cityList({provinceId:provinceid})
  return res.data.cities
}
//获取医院信息
export const gethosplist=async(cityid)=>{
  const { data:res } = await hospList({cityId:cityid})
  // console.log(res);
  if(res.data.hosps==null){
    return []
  }
  return res.data.hosps
}
// 获取科室信息
export const getdepart=async(hospid,departName)=>{
  const { data:res } = await departList({hospId:hospid,departName:departName})
  // console.log(res.data.departs);
  if(res.data.departs==null){
    return []
  }
  // console.log(res);
  return res.data.departs
}

//根据城市医院获取套餐
export const getcombo=async(hospid,cityId)=>{
  const { data:res } = await comboList({
    hospId:hospid,
    cityId:cityId
  })
  if(res.data.combos==null){
    return []
  }
  return res.data.combos
}

//根据 各种东西获取 主治医生
export const getattendingDoctor = async(departId)=>{
  const { data:res } = await attendingDoctorList({
    departId:departId
  })
  if(res.data.doctors==null){
    return []
  }
  return res.data.doctors
}

//根据 城市 医院 套餐 查询回款金额
export const getreturnedAmt=async(cityId,hospId,comboId)=>{
  const { data:res } = await returnedAmt({
    cityId,
    hospId,
    comboId
  })
  
  if(res.data.comboReturnedAmt==null){
    return ''
  }
  return res.data.comboReturnedAmt
}

//获取省份,城市,医院,科室,套餐所选项
export const getoptions = (row,optionid)=>{
  // console.log(row);
  // console.log(optionid);
  return row.filter(item => item.id == optionid).pop() == undefined ?'':row.filter(item => item.id == optionid).pop()
}

//获取主治医师的所选项
export const getdocotrKey = (row,optionid)=>{
  // console.log(row);
  // console.log(optionid);
  return row.filter(item => item == optionid).pop() == undefined ?'':row.filter(item => item == optionid).pop()
}

//获取普通所选项
export const optionget = (row,optionid)=>{
    return row.filter(item => item.key == optionid).pop()
}