<template>
  <div>
    <q-form ref="myForm" greedy class="add">
      <div class="maxgao">
        <!-- 基本资料 -->
        <q-card flat bordered class="my-card add">
          <q-card-section>
            <div class="row justify-between">

              <div style="padding: 0 5px;" class="col-16 col-md-3">
                <!-- 患者姓名 -->
                <q-input class="add" :dense='true' outlined  v-model="getadddata.patientName" label="患者姓名*" lazy-rules :rules='[required.patientNameRules,required.patientNameLength]' />
                <!-- 医院选择 -->
                <selecth @hospchange='hospchange' class='add' :options='getadddata.hosplist' :model.sync='getadddata.hospId' :label='"请选择医院*"'/>
                <!-- 代理商是否付款 -->
                <selectq class='add' :options='isPay' :model.sync='getadddata.ispay' :label='"代理商是否收款"' :rule='required.kong'/>
                <!-- 住院号 -->
                <q-input class="add" :dense='true' outlined  v-model="getadddata.hospNumber" label="住院号" lazy-rules :rules='[required.kong,required.hospNumberLength]'/>
              </div>

              <div style="padding: 0 5px;" class="col-16 col-md-3">
                <!-- 医生姓名 -->
                <q-input class="add" :dense='true' outlined  v-model="getadddata.doctorName" label="医生姓名*" lazy-rules 
                :rules=' getadddata.hospId.hospName == "莲和" ? [required.doctorNameLength] : [required.doctorNameRules,required.doctorNameLength] '/>
                <!-- 科室选择 -->
                <selectd @departchange='departchange' class='add' :options='getadddata.departslist' :model.sync='getadddata.departId' :label='"请选择科室*"'/>
                <!-- 手术时间 -->
                <surgeryDate class='add' :model.sync='getadddata.surgeryDate'/>
                <!-- 优惠码 -->
                <q-input class="add" :dense='true' outlined  v-model="getadddata.discountCode" label="优惠码" lazy-rules :rules='[rules.discountCode]' @blur='discountCodeChange'>
                  <template v-slot:append>
                    <span style="font-size: 12px;">{{(getadddata.discountPercent*10 == 0 ? '' : getadddata.discountPercent*10)}}折</span>
                  </template>
                </q-input>
              </div>

              <div style="padding: 0 5px;" class="col-16 col-md-3">
                <!-- 省份选择 -->
                <selectp @provincechange='provincechange' class='add' :options='getadddata.getprovincelist' :model.sync='getadddata.provinceId' :label='"请选择省份*"' />
                <!-- 套餐选择 -->
                <comboselect @combochange='combochange'  class='add' :options='getadddata.combolist' :model.sync='getadddata.comboId' :label='"请选择套餐*"'/>
                <!-- 订单金额 -->
                <q-input class="add" :disable='true' type="number" :dense='true' outlined
                  :value="getadddata.orderType.key == 0 ? 0 : getadddata.orderAmt"
                  label="订单金额*" lazy-rules :rules='[required.orderlimit]'
                />
              </div>

              <div style="padding: 0 5px;" class="col-16 col-md-3">
                <!-- 城市选择 -->
                <selectc @citychange='citychange' class='add' :options='getadddata.citylist' :model.sync='getadddata.cityId' :label='"请选择城市*"'/>
                <!-- 主管医生下拉框 doctorselect -->
                <doctorselect  class='add' :options='getadddata.doctorlist' :model.sync='getadddata.doctorid' :label='"请选择主管医师"'/>
                <!-- 订单类型 -->
                <selectq class='add' :options='orderType' :model.sync='getadddata.orderType' :label='"订单类型"' :rule='required.kong'
                  @bian='orderTypeChange'  @clear='orderTypeChange' />
              </div>
              <!-- 诊断说明 -->
              <div class='add' style="padding: 0 5px;">
                <q-input
                  :dense='true' 
                  outlined  
                  autogrow
                  type="textarea"
                  v-model="getadddata.diagnoseDesc" 
                  label="诊断说明"
                  :rules="[required.diagnoseDesclength]"
                />
              </div>
              <!-- 订单备注信息 -->
              <div class='add' style="padding: 0 5px;">
                <q-input
                  :dense='true' 
                  outlined  
                  autogrow
                  type="textarea"
                  v-model="getadddata.orderDesc" 
                  label="订单备注信息"
                  :rules="[required.orderDesclength]"
                />
              </div>
            </div>
          </q-card-section>
        </q-card>
        <!-- 检查报告 -->
        <q-card flat bordered class="my-card add">
          <q-card-section>
            <el-upload
              class="upload-demo"
              ref="upload"
              action=""
              :on-change='checkReportFileschange'
              :on-remove="checkReportFilesRemove"
              :file-list="getadddata.checkReportFilesList"
              :auto-upload="false"
              >
              <el-button slot="trigger" size="small" type="primary">选取检查报告</el-button>
              <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>
          </q-card-section>
        </q-card>
        <!-- 影像资料 -->
        <q-card flat bordered class="my-card add">
          <q-card-section>
            <el-upload
              class="upload-demo"
              ref="upload"
              action=""
              accept=".zip,.rar,.7z"
              :on-change='dcmReportFileschange'
              :on-remove="dcmReportFilesRemove"
              :file-list="getadddata.dcmReportFilesList"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取影像资料</el-button>
              <div slot="tip" class="el-upload__tip">支持的文件格式zip,rar,7z且大小不超过2G</div>
              <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>
          </q-card-section>
        </q-card>
      </div>
      
      <!-- 按钮区域 -->
      <div class="addbtn">
        <el-popover
          placement="bottom"
          width="155"
          v-model="visible">
          <p>未保存的数据将被丢弃, 是否继续?</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="quxiao">确定</el-button>
          </div>
          <el-button slot="reference">取消</el-button>
        </el-popover>
          <!-- <q-btn
              flat
              label="取消"
              color="primary"
              v-close-popup
              @click="quxiao"
          /> -->
          <el-button slot="reference" type="primary" @click="addorder" >添加</el-button>
          <el-button slot="reference" type="primary" @click="addcommitsorder" >保存并提交</el-button>
          <!-- <q-btn
              label="添加"
              color="blue-6"
              @click="addorder"
          /> -->
      </div>
    </q-form>

    <!-- 上传文件提示区 -->
    <q-dialog v-model="alert" persistent>
      <q-card style="width: 400px;">
        <q-card-section>
          <div class="wenjian">文件: {{fileName}}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-linear-progress size="25px" :value="checkprogress" color="primary">
            <div class="absolute-full flex flex-center">
              <q-badge color="white" text-color="primary" :label="checkprogressLabel" />
            </div>
          </q-linear-progress>
        </q-card-section>
        <q-card-section class="q-pt-none">
          
        </q-card-section>
      </q-card>
    </q-dialog>
    
  </div>
</template>

<script>
import { mapState } from 'vuex' //vuex获取数据
import { orderAdd,uploadingfile,orderCommit } from '@/api/Order.js'; // 用户订单新增接口
import { discountCodeCheck } from '@/api/Discount.js'

import axios from 'axios'
import { makeDebounce } from "@/utils/debounce.js";  

import { getprovince,getcity,gethosplist,getdepart,getcombo,getattendingDoctor } from '@/api/components/getCity.js'; // 获取城市接口
import surgeryDate from '@/components/Order/surgeryDate' // 时间选择组件
import selectq from '@/components/Order/select' // 普通下拉框组件
import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件
import selecth from '@/components/Order/select/hospselect.vue' // 医院下拉框组件
import selectd from '@/components/Order/select/departsselect.vue' // 科室下拉框组件
import comboselect from '@/components/Order/select/comboselect.vue' // 套餐下拉框组件
import doctorselect from '@/components/Order/select/doctorselect.vue' // 主管医生下拉框组件
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { notifys } from '@/api/components/Notify.js'




export default {
  props:{
    addorderdata: { // 新增订单信息
      type: Object,
      required: true
    }
  },
  created(){
    getprovince(this.getadddata.getprovincelist)
  },
  data() {
    return {
      visible: false, //控制取消是否关闭弹窗

      required, //表单验证规则

      // orderTypemodel: '', // 订单类型选择
      // ispaymodel: '', //代理商是否付款选择,
      checkReportFilesList: [], //检查报告储存信息
      dcmReportFilesList: [], // 影像资料储存信息
      addmassage: '',
      alert: false, // 控制上传文件提示

      checkprogress: 0, //进度条
      checkprogressLabel: '', //进度百分比
      fileName: '', // 文件名

      addcommit: false, // 控制是否触发提交

      lastTime:0, //上一次计算时间
      lastSize:0, //上一次计算的文件大小

      // discountPercent: '', //折扣力度  可用优惠码 icaaae2k
      // discountCodeAdopt: true, //优惠码是否通过
      // 优惠码校验
      rules:{
        discountCode: val => val.length <= 10 || '优惠码最大长度为10',
      }
    };
  },
  components:{
    selectq, // 普通下拉组件
    selectp, // 省份下拉框组件,
    selectc, //城市下拉框
    selecth, // 医院下拉框
    selectd, // 科室下拉框
    comboselect, //套餐下拉框
    doctorselect,//主管医生下拉框
    surgeryDate,
  },
  computed:{
    ...mapState([
      'orderType',//订单类型
      'isPay', //代理商是否付款
    ]),
    getadddata:{
      get(){
        return this.addorderdata;
      },
      set(v){
        this.$emit('update:addorderdata',v)
      }
    }
  },
  watch:{
  },
  methods: {
    // 优惠码失去焦点时
    async discountCodeChange(){
      if(this.getadddata.discountCode.length){
        const { data: res } = await discountCodeCheck({
          discountCode: this.getadddata.discountCode
        })
        console.log(res);
        // 优惠码校验通过
        if(res.code==20000){
          this.getadddata.discountCodeAdopt = true
          this.getadddata.discountPercent = res.data.discount.discountPercent //储存折扣力度
          //如果套餐金额已经有了就直接修改套餐金额
          if(this.getadddata.comboId.comboAmt){
            this.getadddata.orderAmt = (this.getadddata.comboId.comboAmt * (this.getadddata.discountPercent * 100 )) / 100
          }
        }
      }else{
        this.getadddata.discountCodeAdopt = true
        this.getadddata.discountPercent = '' //验证失败清空折扣力度
        // 如果有套餐金额就 还原正常的套餐金额
        if(this.getadddata.comboId.comboAmt){
          this.getadddata.orderAmt=this.getadddata.comboId.comboAmt
        }
      }
      
    },
    // 省份变化时获取城市列表
    provincechange(){
      this.getadddata.citylist=[]
      this.getadddata.cityId =''
      this.getadddata.hosplist=[]
      this.getadddata.hospId =''
      this.getadddata.departslist=[]
      this.getadddata.departId =''
      this.getadddata.combolist=[]
      this.getadddata.comboId =''

      this.getadddata.doctorlist=[] //清空主管医生
      this.getadddata.doctorid =''

      getcity(this.getadddata.citylist,this.getadddata.provinceId.id)
    },
    // 城市变化时获取医院列表
    citychange(){
      // console.log(this.getadddata.provinceId.id);只有这样才能获取到id值
      this.getadddata.hosplist=[]
      this.getadddata.hospId =''
      this.getadddata.departslist=[]
      this.getadddata.departId =''
      this.getadddata.combolist=[]
      this.getadddata.comboId =''
      
      this.getadddata.doctorlist=[] //清空主管医生
      this.getadddata.doctorid =''

      gethosplist(this.getadddata.hosplist,this.getadddata.cityId.id)
    },
    // 医院发生变化时
    hospchange(){
      this.getadddata.departslist=[]
      this.getadddata.departId =''
      this.getadddata.combolist=[]
      this.getadddata.comboId =''

      this.getadddata.doctorlist=[] //清空主管医生
      this.getadddata.doctorid =''

      getdepart(this.getadddata.departslist,this.getadddata.hospId.id)
      getcombo(this.getadddata.combolist,this.getadddata.hospId.id,this.getadddata.cityId.id)
    },
    // 科室发生变化时
    departchange(){
      this.getadddata.doctorlist=[] //清空主管医生
      this.getadddata.doctorid =''

      getattendingDoctor(this.getadddata.doctorlist,this.getadddata.departId.id) // 获取主管医生列表
    },
    // 套餐变化时
    combochange(){
      this.getadddata.orderAmt =''
      this.getadddata.orderAmt=this.getadddata.comboId.comboAmt
      if(this.getadddata.discountPercent){
        this.getadddata.orderAmt = (this.getadddata.comboId.comboAmt * (this.getadddata.discountPercent * 100)) / 100
      }
      // console.log(this.getadddata.comboId.comboAmt);
    },
    // 检查报告文件删除时
    checkReportFilesRemove(file,fileList) {
      console.log(file);
      const index = this.getadddata.checkReportFilesList.findIndex(item => item.uid === file.uid)
      console.log(index);
      this.getadddata.checkReportFilesList.splice(index,1)
    },
    // 检查报告文件发生改变时
    checkReportFileschange(file, fileList){
      // console.log('file',file);
      // console.log('fileList',fileList);
      console.log(file.raw);
      console.log(file);
      this.getadddata.checkReportFilesList.push(file.raw)
      console.log(this.getadddata.checkReportFilesList);
    },

    //影像资料改变时
    dcmReportFileschange(file, fileList){
      let fileName = file.name;
      let uid = file.uid
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      // 限制上传文件的大小
      const isLt = file.size / 1024/ 1024 / 1024 / 2 <= 1;
      if (!isLt) {
        notifys('negative',`上传文件不得大于2G!`)
        for(var i = 0;i<fileList.length;i++) {
          if(fileList[i].uid == uid) {
            fileList.splice(i,1)
          }
        }
        return
      }
      
      let Array1=''
      let Array2=''
      let Array3=''
      let Array4=''
      let Array5=''
      let Array6=''
      let Array7=''
      let Array8=''
      // 限制文件类型
      var reader = new FileReader();
      reader.readAsArrayBuffer(file.raw);
      reader.onload =  (da) =>{ //有一个异步回调
        console.log(da.target.result);

        let yy = new Uint8Array(da.target.result) //后端需要的二进制格式
        Array1 = yy[0]
        Array2 = yy[1]
        Array3 = yy[2]
        Array4 = yy[3]
        Array5 = yy[4]
        Array6 = yy[5]
        Array7 = yy[6]
        Array8 = yy[7]

        if(Array1==55 && Array2==122 && Array3==188 && Array4==175 && Array5==39 && Array6==28 && Array7==0 && Array8==3){
          console.log('7z文件');
        }else if(Array1==80 && Array2==75 && Array3==3 && Array4==4){
          console.log('zip文件');
        }else if(Array1==82 && Array2==97 && Array3==114 && Array4==33 && Array5==26 && Array6==7 && Array7==0){
          console.log('rar4文件');
        }else if(Array1==82 && Array2==97 && Array3==114 && Array4==33 && Array5==26 && Array6==7 && Array7==1 && Array8==0){
          console.log('rar5文件');
        }else{
          for(var i = 0;i<fileList.length;i++) {
            if(fileList[i].uid == uid) {
              fileList.splice(i,1)
            }
          }
          notifys('negative',`文件本身必须为.rar .zip .7z 类型`)
          return
        }
        console.log('Array1',Array1);
        console.log('Array2',Array2);
        console.log('Array3',Array3);
        console.log('Array4',Array4);
        console.log('Array5',Array5);
        console.log('Array6',Array6);
        console.log('Array7',Array7);
        console.log('Array8',Array8);
        return this.getadddata.dcmReportFilesList.push(file.raw)
      }
    },
    //影像资料删除时
    dcmReportFilesRemove(file,fileList){
      console.log(file);
      const index = this.getadddata.dcmReportFilesList.findIndex(item => item.uid === file.uid)
      console.log(index);
      this.getadddata.dcmReportFilesList.splice(index,1)

    },
    //点击取消时
    quxiao(){
      this.$refs.myForm.resetValidation()
      this.visible= false
      this.$emit('addconfirmclose') // 清空表单内容
    },

    // 保存并提交
    addcommitsorder:makeDebounce(function(){
      this.$q.dialog({
        title: '提交审核',
        message: `你确定要保存并提交此订单吗?`,
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(() => {
        try{
            // 开启添加订单并提交
            this.addcommit = true
            this.addorder()
          }catch (e){
              console.log(e);
          }
      })
    },500),


    // 提交订单时
    addorder:makeDebounce(function(){
      console.log(this.getadddata.discountCode);
      const checkReportFiles= this.getadddata.checkReportFilesList
      const dcmReport = this.getadddata.dcmReportFilesList
      const orderTypes= this.getadddata.orderType.key != undefined ? this.getadddata.orderType.key : ''
      const isPays= this.getadddata.ispay.key != undefined ? this.getadddata.ispay.key : ''
      // 优惠码校验未通过
      if(!this.getadddata.discountCodeAdopt){
        notifys('negative',`优惠码不可用请重新输入或清空优惠码`)
        return
      }
      this.$refs.myForm.validate().then( async outcome =>{
        if (outcome){
          const data = new FormData()
          data.set("patientName", this.getadddata.patientName)
          data.set("provinceId", this.getadddata.provinceId.id)
          data.set("cityId", this.getadddata.cityId.id)
          data.set("hospId", this.getadddata.hospId.id)
          data.set("comboId", this.getadddata.comboId.id)
          data.set("departId", this.getadddata.departId.id)
          data.set("attendingDoctor", this.getadddata.doctorid) //主管医师
          data.set("doctorName", this.getadddata.doctorName)
          data.set("diagnoseDesc", this.getadddata.diagnoseDesc)
          data.set("orderDesc", this.getadddata.orderDesc)
          data.set("orderType", orderTypes)
          data.set("isPay", isPays)
          data.set("surgeryDate", this.getadddata.surgeryDate)
          data.set("orderAmt", this.getadddata.orderAmt)
          data.set("hospNumber", this.getadddata.hospNumber)
          data.set("discountCode", this.getadddata.discountCode) //优惠码
          const { data: res } = await orderAdd(
            data
          )
          
          console.log(res);
          if(res.code===20000){
            const orderId = res.data.orderId
            // 如果检查报告或影像资料有一个不为空
            if(checkReportFiles.length!=0||dcmReport.length!=0){
              this.alert = true
              // 如果检查报告不为空
              if(checkReportFiles.length!=0){
                for (let i = 0; i < checkReportFiles.length; i++){
                  try{
                    this.checkprogress=0
                    this.checkprogressLabel=''
                    console.log(checkReportFiles[i]);
                    this.fileName = checkReportFiles[i].name
                    const filedata = new FormData()
                    filedata.append('informationFile',checkReportFiles[i])
                    filedata.append('fileType',0)
                    filedata.append('orderId',orderId)
                    // console.log(filedata);
                    const { data: res } = await uploadingfile(filedata, event => {
                      console.log(event);
                      // event.loaded
                      // event.total
                      this.checkprogress = event.loaded/event.total
                      this.checkprogressLabel=`文件已上传${((event.loaded/event.total)*100).toFixed(2)}%`
                    })
                    if(res.code != 20000){
                      this.alert = false
                      notifys('negative',`文件${checkReportFiles[i].name},${res.message}`)
                    }
                  }catch (e) {
                    this.alert = false
                    notifys('negative',`文件传输失败,请检查网络`)
                  }
                }
              }
              // 如果影像资料不为空
              if(dcmReport.length!=0){
                for (let i = 0; i < dcmReport.length; i++){
                  try{
                    this.checkprogress=0
                    this.checkprogressLabel=''
                    this.fileName = dcmReport[i].name
                    const filedata = new FormData()
                    filedata.append('informationFile',dcmReport[i])
                    filedata.append('fileType',1)
                    filedata.append('orderId',orderId)
                    // console.log(filedata);
                    const { data: res } = await uploadingfile(filedata, event=>{
                      console.log(event);
                      this.checkprogress = event.loaded/event.total
                      this.checkprogressLabel=((event.loaded/event.total)*100).toFixed(2) + '%'
                      this.progressHandle(event)
                    })
                    console.log(res);
                    if(res.code != 20000){
                      this.alert = false
                      this.lastTime = 0;//上一次计算时间
                      this.lastSize = 0;//上一次计算的文件大小
                      notifys('negative', `文件${dcmReport[i].name},${res.message}`)
                    }
                  }catch (e){
                    this.alert = false
                    notifys('negative',`文件传输失败,请检查网络`)
                  }
                }
              }
              this.alert = false
            }
            // 如果点击的是保存并提交 就 提交
            if(this.addcommit){
              const { data: res } = await orderCommit({orderId: orderId})
              console.log(res);
              if(res.code===20000){
                this.addcommit = false
                notifys('positive',res.message)
              }else{
                this.addcommit = false
                notifys('negative',res.message)
              }
            }
            this.$refs.myForm.resetValidation()
            this.$emit('addconfirmclose') // 清空表单内容
            this.$emit('refreshlist') // 刷新页面
            notifys('positive',res.message)
          }else{
            notifys('negative',res.message)
          }
        }
      })
    },500),

    //计算大小 速度 剩余时间
    progressHandle(event) {
      // this.lastTime = 0;//上一次计算时间
      // this.lastSize = 0;//上一次计算的文件大小
      /*计算间隔*/
      var nowTime = new Date().getTime();
      //当前时间 - 上一次计算时间
      var intervalTime = (nowTime - this.lastTime)/1000;//时间单位为毫秒，需转化为秒
      // 文件当前完成的数量 - 上一次文件完成数量
      var intervalSize = event.loaded - this.lastSize;

      /*重新赋值以便于下次计算*/
      this.lastTime = nowTime;
      this.lastSize = event.loaded;

      /*计算速度*/
      // 完成数量的差 / 时间差
      var speed = intervalSize/intervalTime;
      console.log(intervalSize);
      console.log(intervalTime);

      console.log(speed);
      var bSpeed = speed;//保存以b/s为单位的速度值，方便计算剩余时间
      var units = 'b/s';//单位名称
      if(speed/1024>1){
          speed = speed/1024;
          units = 'k/s';
      }
      if(speed/1024>1){
          speed = speed/1024;
          units = 'M/s';
      }

      /*计算剩余时间*/
      var leftTime = ((event.total - event.loaded) / bSpeed); //剩余总秒数
      let secondTime = parseInt(leftTime)
      let min = 0// 初始化分
      let h = 0// 初始化小时
      let result=''
      if(secondTime>60){//如果秒数大于60，将秒数转换成整数
        min=parseInt(secondTime/60)//获取分钟，除以60取整数，得到整数分钟
        secondTime=parseInt(secondTime%60)//获取秒数，秒数取佘，得到整数秒数
        if(min>60){//如果分钟大于60，将分钟转换成小时
          h=parseInt(min/60)//获取小时，获取分钟除以60，得到整数小时
          min=parseInt(min%60) //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result=`${h.toString().padStart(2,'0')}:${min.toString().padStart(2,'0')}:${secondTime.toString().padStart(2,'0')}`
      /*计算进度*/
      var progress = event.loaded/event.total * 100;

      /* 计算平均速度 */
      // this.allUpload.UploadSpeed = speed.toFixed(1) + units

      // console.log("当前进度：" + progress.toFixed(1) + "%    当前速度：" + speed.toFixed(1) + units +"   预计剩余时间：" + leftTime.toFixed(1) + "秒");
      console.log("当前进度：" + progress.toFixed(1) + "%    当前速度：" + speed.toFixed(1) + units +"   预计剩余时间：" + result);
    },
    
    // 订单类型改变时
    orderTypeChange(){
      this.getadddata.orderAmt = this.getadddata.orderAmt
      // 如果是切换成付费订单就需要校验验证码
      if(this.getadddata.orderType.key != 0){
        this.discountCodeChange()
      }
    },

  },
};

</script>

<style lang='less' scoped>
.maxgao{
  max-height: 50vh;
  overflow: auto;
  margin-bottom: 15px;
}
.my-card{
  width: 100%;
  margin-bottom: .5rem;
}
.add {
  width: 100%;
  margin-left: 0;
  margin-top: 0.1875rem;
}
.add2{
  padding: 0 3px;
}
.addbtn {
  display: flex;
  justify-content: space-between;
  margin-left: 0;
}
.wenjian{
  font-size: 17px;
  font-weight: bold;
}
</style>
