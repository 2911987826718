<template>
  <div>
    <q-select
        ref="sel"
        outlined
        :disable='disable'
        v-model="getmodel"
        :options="options"
        class="operation"
        :label="label"
        option-label="value"
        color="teal"
        :dense="true"
        :rules="[rule]"
        lazy-rules
        @input='bian'
        options-selected-class="text-deep-orange"
    >
        <template v-if=" model && !disable" v-slot:append>
          <q-icon name="close" @click.stop="clear" class="cursor-pointer shanchu" />
        </template>
        <template v-slot:option="scope">
            <q-item
                v-bind="scope.itemProps"
                v-on="scope.itemEvents"
            >
                <q-item-section>
                    <q-item-label>{{
                        scope.opt.value
                    }}</q-item-label>
                </q-item-section>
            </q-item>
        </template>
    </q-select>
  </div>
</template>

<script>
export default {
    props:{
        options:{
            type: Array,
            required: true
        },
        model:{
            type: [String,Object],
            required: true
        },
        label:{
            type: String,
            required: true
        },
        rule:{
            type: [Function]
        },
        disable:{
            type: Boolean
        }
    },
    created(){},
    data() {
        return {
        };
    },
    components:{},
    computed:{
        getmodel:{
            get(){
                return this.model;
            },
            set(v){
                this.$emit('update:model',v)
            }
        }
    },
    watch:{
    },
    methods: {
        bian(){
            // console.log('1');
            this.$emit('bian')
            this.$refs.sel.blur()
        },
        clear(){
            // console.log('1');
            this.getmodel = ''
            this.$emit('clear')
            this.$refs.sel.blur()
        },
        
    },
};
</script>

<style lang='less' scoped>
.shanchu{
    font-size: 16px;
}
</style>
