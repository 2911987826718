<template>
  <div id="Order" class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
      <q-breadcrumbs-el label="订单管理" icon="iconfont icon-dingdanguanli" />
      <q-breadcrumbs-el label="用户订单" icon="iconfont icon-dingdan" to="/order/list" />
    </q-breadcrumbs>
    <!-- 总卡片区 -->
    <div class="orderbox" id="orderbox">
      <!-- 搜索卡片区 -->
      <q-card class="my-card" style="margin-bottom: 10px;">
        <q-card-section>
          <div class="searchbox" id="searchbox">
              <div class="search">
                <div class="row">
                  <div class="col">
                    <q-input class='operation' :dense='true' outlined  v-model="queryData.name" label="患者,医生姓名">
                      <template v-if="queryData.name" v-slot:append>
                        <q-icon name="close" @click.stop="queryData.name = ''" class="cursor-pointer" style="font-size: 16px;"/>
                      </template>
                    </q-input>
                  </div>
                  <!-- <div class="col">
                    <q-input class='operation' :dense='true' outlined  v-model="text" label="订单号" />
                  </div> -->
                  <div class="col">
                    <!-- 下拉框组件 -->
                    <selectq class='operation' :options='orderStatus' :model.sync='orderStatusmodel' :label='"订单状态"'/>
                  </div>
                  <div class="col">
                    <!-- 下拉框组件 -->
                    <selectq class='operation' :options='orderType' :model.sync='orderTypemodel' :label='"订单类型"' />
                  </div>
                  <div class="col">
                    <el-date-picker
                      style="width: 95%;height: 40px;"
                      size='mini'
                      class='operation'
                      value-format='yyyy-MM-dd'
                      v-model="dayvalue2"
                      type="daterange"
                      align="right"
                      unlink-panels
                      @change='daybian'
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                  </div>
                  
                </div>
              </div>
              <div>
                  <el-button
                  :disabled='Permissionslist.filter(item=>item==="order.list").length>0?false:true'
                  type="primary"
                  size='medium'
                  @click='search'
                  >
                  搜索
                  </el-button>
                  <el-button
                  :disabled='Permissionslist.filter(item=>item==="order.list").length>0?false:true'
                  type="primary"
                  size='medium'
                  @click='chongzhi'
                  >
                  重置
                  </el-button>
                  <el-button
                  :disabled='Permissionslist.filter(item=>item==="order.add").length>0?false:true'
                  type="primary"
                  size='medium'
                  @click='tianjia'
                  >
                  新增订单
                  </el-button>
              </div> 
          </div>
        </q-card-section>
      </q-card>
      <q-card class="my-card">
        <!-- <q-card-section class='tabletop'>
         
        </q-card-section> -->
        <q-card-section>
          <el-table
          v-loading="ordervisible"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(55,55,55,0.2)"
          @sort-change="sortthiscolumn"
          ref="multipleTable"
          :data="orderList"
          tooltip-effect="dark"
          style="width: 100%"
          :max-height="tableHeight"
          > 
            <template slot="empty">
              {{nodatetype}}
            </template>
            <el-table-column v-if="morePopoverData[0].orderId" sortable="custom" show-overflow-tooltip align='center' prop="orderId" label="订单号" min-width="93px"></el-table-column>
            <!-- 患者姓名 -->
            <el-table-column v-if="morePopoverData[1].patientName" sortable="custom" show-overflow-tooltip align='center' prop="patientName" label="患者姓名" min-width='104px'></el-table-column>
            <!-- 加急订单 -->
            <el-table-column v-if="morePopoverData[2].Urgent && urgenthide" align='center' width='70px'>
              <template slot-scope="{row}">
                <el-tag v-if='row.urgentStatus' type="danger" style='padding:0 5px;'>加急</el-tag>
                <el-tag v-if='row.weekDay' type="danger" style='padding:0 5px;'>周末</el-tag>
              </template>
            </el-table-column>
            <!-- 主管医师 -->
            <el-table-column v-if="morePopoverData[3].attendingDoctor" sortable="custom" show-overflow-tooltip align='center' prop="attendingDoctor" label="主管医师" min-width='104px'></el-table-column>
            <el-table-column v-if="morePopoverData[4].doctorName" sortable="custom" show-overflow-tooltip align='center' prop="doctorName" label="医生姓名" min-width='104px'></el-table-column>
            <el-table-column v-if="morePopoverData[5].createUser" sortable="custom" show-overflow-tooltip align='center' prop="createUser" label="下单人" min-width="93px" ></el-table-column>
            <el-table-column v-if="morePopoverData[6].orderAmt" sortable="custom" align='center' prop="orderAmt" label="订单金额" min-width='104px'></el-table-column>
            <!-- 回款金额 -->
            <!-- <el-table-column v-if="morePopoverData[17].orderReturnedAmt" sortable="custom" align='center' prop="orderReturnedAmt" label="回款金额" min-width='104px'></el-table-column> -->
            <el-table-column v-if="morePopoverData[7].provinceName" sortable="custom" show-overflow-tooltip align='center' prop="provinceName" label="省份"></el-table-column>
            <el-table-column v-if="morePopoverData[8].cityName" sortable="custom" show-overflow-tooltip align='center' prop="cityName" label="城市"></el-table-column>
            <el-table-column v-if="morePopoverData[9].hospName" sortable="custom" show-overflow-tooltip align='center' prop="hospName" label="医院"></el-table-column>
            <el-table-column v-if="morePopoverData[10].comboName" sortable="custom" show-overflow-tooltip align='center' prop="comboName" label="套餐"></el-table-column>
            <el-table-column v-if="morePopoverData[11].surgeryDate" sortable="custom" align='center' prop="surgeryDate" label="手术时间" min-width='151px'></el-table-column>
            <el-table-column v-if="morePopoverData[12].createTime" sortable="custom" align='center' prop="createTime" label="创建时间" min-width='151px'></el-table-column>
            <el-table-column v-if="morePopoverData[13].accessCode" sortable="custom" align='center' prop='accessCode' label="提取码" min-width="93px" ></el-table-column>
            <!-- 代理商是否收款 -->
            <el-table-column v-if="morePopoverData[14].isPay" sortable="custom" align='center' prop="isPay" label="代理商是否收款" min-width="143px" >
              <template slot-scope="scope">
                <q-chip v-show="scope.row.isPay!=null" square :color="scope.row.isPay==true?'positive':'warning'" text-color="white">
                  {{
                    (isPay.filter(item=>item.key == scope.row.isPay).pop() || {} ).value
                  }}
                </q-chip>
              </template>
            </el-table-column>
            <!-- 订单类型 -->
            <el-table-column sortable="custom" v-if="morePopoverData[15].orderType" align='center' prop="orderType" label="订单类型" min-width="117px" >
              <template slot-scope="scope">
                <q-chip v-show="scope.row.orderType!=null" square :color="orderTypecolor[scope.row.orderType]" text-color="white">
                  {{ (orderType.filter(item=>item.key == scope.row.orderType).pop() || {} ).value }}
                </q-chip>
              </template>
            </el-table-column>
            <!-- 支付状态 -->
            <el-table-column v-if="morePopoverData[16].payStatus" sortable="custom" align='center' prop="payStatus" label="支付状态" min-width="100px" >
              <template slot-scope="scope">
                <q-chip square :color="payStatuscolor[scope.row.payStatus]" text-color="white">
                  <!-- {{ payStatus.row.payStatus==0? '待支付':'已支付' }} -->
                  {{ (payStatus.filter(item=>item.key == scope.row.payStatus).pop() || {} ).value }}
                </q-chip>
              </template>
            </el-table-column>
            <!-- 订单状态 -->
            <el-table-column v-if="morePopoverData[17].orderStatus" sortable="custom" align='center' prop="orderStatus" label="订单状态" min-width="125px" >
              <template slot-scope="scope">
                <q-chip square :color="orderStatuscolor[scope.row.orderStatus]" text-color="white">
                  {{ (orderStatus.filter(item=>item.key == scope.row.orderStatus).pop() || {} ).value }}
                </q-chip>
              </template>
            </el-table-column>
            <!-- <el-table-column align='center' prop="orderType" label="订单类型" >
              <template slot-scope="orderTypes">
                {{ (orderType.filter(item => item.key == orderTypes.row.orderType ).pop() || {}).value }}
              </template>
            </el-table-column> -->
            <el-table-column align='center' min-width="230" width="250"  fixed="right">
              <template slot="header">
                <div class='newCaozuobox'>
                  <div class='czTit'>操作</div>
                  <div class='czpopoverbtn'>
                    <morePopover
                      :item.sync='morePopoverData'
                    />
                  </div>
                </div>
              </template>
              <template slot-scope="anniu">
                <!-- 编辑 -->
                <q-btn
                  :disable='Permissionslist.filter(item=>item==="order.edit").length>0?false:true'
                  class="czbtn"
                  size="sm"
                  color="blue"
                  icon="iconfont icon-Edit"
                  @click="editorder(anniu.row)"
                >
                  <q-tooltip content-class="bg-blue" :offset="[10, 10]">
                    编辑
                  </q-tooltip>  
                </q-btn> 
                <!-- 提交 -->
                <q-btn 
                  :disable='Permissionslist.filter(item=>item==="order.commit").length>0 && (anniu.row.orderStatus== 0 || anniu.row.orderStatus==3) ? false : true'  
                  class="czbtn"  
                  size="sm" 
                  color="green-5" 
                  icon="iconfont icon-tijiao" 
                  @click="commits(anniu.row)"
                >
                  <q-tooltip content-class="bg-green-5" :offset="[10, 10]">
                    提交
                  </q-tooltip> 
                </q-btn>
                <!-- 更多 -->
                <el-popover
                  style="overflow: hidden;"
                  width='80'
                  trigger="hover">
                    <q-btn
                      :disable='Permissionslist.filter(item=>item==="order.edit").length>0?false:true'
                      style="width:100%;"
                      class="czbtn2"  
                      size="sm"
                      color="yellow-10" 
                      icon="iconfont icon-chakan" @click="checkorder(anniu.row)"
                    >
                    查看
                    </q-btn>
                    <q-btn
                      :disable='Permissionslist.filter(item=>item==="order.result").length>0 &&anniu.row.orderStatus== 5 ? false : true'
                      style="width:100%;"
                      class="czbtn2"  
                      size="sm"
                      color="yellow-10" 
                      icon="iconfont icon-sanweimoxing" @click="getinformation(anniu.row)"
                    >
                      重建结果
                    </q-btn>

                    <q-btn
                      :disable='Permissionslist.filter(item=>item==="order.delete").length>0 && anniu.row.orderStatus== 0 && anniu.row.payStatus== 0? false : true' 
                      style="width:100%;"
                      class="czbtn2"  
                      size="sm"
                      color="red-9" 
                      icon="iconfont icon-ashbin" @click="orderDel(anniu.row)"
                    >
                      删除
                    </q-btn>

                  <el-button class="morebtn" size="mini" icon="el-icon-more" slot="reference">更多</el-button>
                </el-popover>
              <!-- 下拉按钮 -->
                
              </template>
            </el-table-column>
          </el-table>
          <elementPagination class="Paginationstyle" :total='total' :queryData='queryData'  @changepageSize='changepageSize' @changePage='changePage'/>
        </q-card-section>
      </q-card>
    </div>
     <!-- 新增弹窗 -->
    <el-dialog :visible.sync="addconfirm" width="70%" title="新增订单" :close-on-click-modal='false' @close="addconfirmclose">
      <orderadd
        class="el-dialog__body"
        ref='orderadd'
        :addorderdata.sync='addorderdata'
        @addconfirmclose='addconfirm =false'
        @refreshlist='getOrderList'
      >
      </orderadd>
    </el-dialog>  
    <!-- 查看弹窗 -->
    <el-dialog :visible.sync="checkconfirm" width="70%" title="查看订单" :close-on-click-modal='false' @close="checkconfirm = false">
      <ordercheck
        v-if="checkconfirm"
        class="el-dialog__body"
        ref='ordercheck'
        :checkAllData.sync='checkAllData'
        :checkData.sync='checkData'
        @closedialog='closecheckdialog'
      >
      </ordercheck>
    </el-dialog> 
    <!-- 编辑弹窗 -->
    <el-dialog :visible.sync="editconfirm" width="70%" title="编辑订单" :close-on-click-modal='false' @close="closeeditdialog">
      <orderedit
        v-if="editconfirm"
        class="el-dialog__body"
        ref='orderedit'
        :checkAlllist.sync='checkAlllist'
        :editAllData.sync='editAllData'
        :editData.sync='editData'
        @closeeditdialog='closeeditdialog'
        @getOrderList='getOrderList'
      >
      </orderedit>
    </el-dialog> 
    <!-- 查看重建结果 -->
    <el-dialog :visible.sync="informationconfirm" width="450px" title="查看重建结果" :close-on-click-modal='false' @close="information=false">
      <informationresult
        class="el-dialog__body"
        ref='informationresult'
        :resultlist='resultlist'
        :imgsrc='imgsrc'
        :imgurl='imgurl'
        :huanzhe.sync='huanzhe'
      >
      </informationresult>
    </el-dialog> 
  </div>
  
</template>

<script>
import { mapState } from 'vuex'
import jwt from "jsonwebtoken";
import QRCode from 'qrcode'  // 生成二维码插件
import elementPagination from '@/components/ElementUI/elementPagination.vue' // 分页组件
// import dateq from '@/components/Order/date' // 时间选择组件
import selectq from '@/components/Order/select' // 下拉框组件
import orderadd from '@/components/Order/AddOrder.vue' // 添加订单组件
import ordercheck from '@/components/Order/checkOrder.vue' // 查看订单组件
import orderedit from '@/components/Order/editOrder.vue' // 编辑订单组件
import informationresult from '@/components/Order/informationresult.vue' // 查看重建订单组件
import morePopover from '@/components/ElementUI/morePopover.vue'
import { makeDebounce } from "@/utils/debounce.js";  
import {
  getItem,
} from '@/utils/storage.js'
import { getprovince,getcity,gethosplist,getdepart,getcombo,getattendingDoctor,getoptions,optionget,getdocotrKey } from '@/api/components/checkcitys.js'; // 获取城市接口
import { orderCheckpay } from '@/api/orderCheck.js'; // 订单审核接口

import { orderList,orderCheck,orderDelete,orderCommit,checkInformationResult } from '@/api/Order.js'; // 用户订单增删改查接口
import { discountCodeCheck } from '@/api/Discount.js'

import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度
import { notifys } from '@/api/components/Notify.js'


export default {
  created(){
    this.getOrderList()
    document.onkeydown=e=>{
      let _key = window.event.keyCode;
      if(_key===13){
        this.search()
      }  
    }
  },
  data() {
    return {
      tableHeight:200, // 列表最大高度

      morePopoverData:[
        {prop:'orderId',value: '订单号',orderId: false},//0
        {prop:'patientName',value: '患者姓名',patientName: true}, //1
        {prop:'Urgent',value: '订单加急',Urgent: true}, //3 Urgent 加急
        {prop:'attendingDoctor',value: '主管医师',attendingDoctor: false}, //2
        {prop:'doctorName',value: '医生姓名',doctorName: true}, //4 
        {prop:'createUser',value: '下单人',createUser: false}, //5
        {prop:'orderAmt',value: '订单金额',orderAmt: true}, //6
        {prop:'provinceName',value: '省份',provinceName: false}, //7
        {prop:'cityName',value: '城市',cityName: false, style: 'margin-right: 8px;'}, //8
        {prop:'hospName',value: '医院',hospName: false,style: 'margin-right: 8px;'}, //9
        {prop:'comboName',value: '套餐',comboName: false}, //10
        {prop:'surgeryDate',value: '手术时间',surgeryDate: false}, //11
        {prop:'createTime',value: '创建时间',createTime: true}, //12
        {prop:'accessCode',value: '提取码',accessCode: false}, //13
        {prop:'isPay',value: '代理商是否收款',isPay: false}, //14
        {prop:'orderType',value: '订单类型',orderType: false}, //15
        {prop:'payStatus',value: '支付状态',payStatus: true}, //16
        {prop:'orderStatus',value: '订单状态',orderStatus: true}, //17
        // {prop:'orderReturnedAmt',value: '回款金额',orderReturnedAmt: false}, //17

        
      ], //

      ordervisible: false,//控制loading效果
      nodatetype: '' , //表格无数据时显示
      //时间区间选择
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
      // 省份到套餐,主治医师总数据
      checkAlllist:{
        province:[],
        city:[],
        hosp:[],
        depart:[],
        combo:[],
        attendingDoctor:[], 
      },
      orderStatuscolor:{
        0: 'warning',
        1: 'primary',
        2: 'primary',
        3: 'negative',
        4: 'primary',
        5: 'positive',
      },
      orderTypecolor:{
        0: 'positive',
        1: 'primary',
      },
      payStatuscolor:{ //支付状态
        0: 'primary',
        1: 'positive',
      },
      
      patient: true, //控制患者列
      doctor: true, //控制医生列
      Amt: true, //控制金额
      hos: true, //控制医院
      cTime: true, //控制创建时间
      orderS: true, //控制订单状态
      payS: true, //控制支付状态
      
      attendingDoctor:false, //主管医师
      accessCode: false, //控制提取码
      province: false, //控制省份
      city: false, // 控制城市
      sTime: false, //控制手术时间
      pay: false, //控制代理商是否收款
      orderID: false, //控制订单号
      createU: false, // 控制下单人
      orderT: false, // 控制订单类型

      orderList:[], // 订单列表
      urgenthide: false,//是否展示加急列

      dayvalue2: null, //订单开始和结束时间
      queryData:{ // element分页信息
        page: 1,
        pageSize: 10,
        startDate:'', // 开始时间
        endDate:'', //结束时间
        name:''
      },
      sortField:'', // 控制那列排序
      sortType:'', // 控制排序类型
      
      orderStatusmodel:'', // 取出时使用orderStatusmodel.key 就可以获取vlaue值 订单状态 订单查询使用
      orderTypemodel:'', // 取出时使用orderTypemodel.key 就可以获取vlaue值 订单类型 订单查询使用
      total:0, //总条数
      pagination: {//quasar分页设置 
        page: 1,
        rowsPerPage: 20, //设置默认是显示多少条,
      },
      
      addconfirm: false, // 控制新增
      addorderdata:{
        patientName:'', //患者名字
        provinceId:'',  //省份id
        cityId:'', // 城市id
        hospId:'', // 医院id
        comboId:'', // 套餐id
        departId:'', //科室id
        doctorid:'', //主管医生id   

        doctorName: '', //医生名字
        diagnoseDesc: '', //诊断说明
        orderDesc:'', // 订单备注信息
        orderType: '', //订单类型
        ispay:'', //代理商是否付款
        surgeryDate: "", //手术时间
        orderAmt: '' , // 订单金额
        discountCode:'', //优惠码
        discountCodeAdopt: true, //优惠码是否通过
        discountPercent:'', //折扣力度

        checkReportFilesList:[],//检查报告资料
        dcmReportFilesList:[],//影像资料报告

        getprovincelist: [], //省份信息
        citylist: [], //省份信息
        hosplist:[], //医院信息
        departslist:[], // 科室信息
        combolist:[], //套餐信息
        doctorlist:[], //主管医生信息

        hospNumber:'', //住院号
        paydisable: null, //公众号基础信息修改
        userType:'', //用户类型
      },

      checkconfirm: false, //控制查看
      checkAllData:null, // 查看订单的总信息
      checkData:{ // 查看用户所需数据
        orderTypeoptions: '', // 订单类型所选项
        orderStatusoptions: '', // 订单状态所选项
        payStatusoptions: [], // 支付状态
        reject:'', // 驳回信息
        checkReportFilesList:[],//检查报告资料
        dcmReportFilesList:[], //影像资料报告
        invoiceList: [], // 发票信息
        ispayoptions: '', // 代理商是否付款
        surgeryDate:'', //手术时间
        discountCode:'',//优惠码
        discountPercent:'', //折扣力度
        urgentAmt:'', //加急金额
        orderReturnedAmt:'', //回款金额
        payId:'',// 订单流水
        userType:'', //用户类型
        returnedAmtDisable: null, //控制是否显示回款金额
      },

      editconfirm: false, //控制编辑
      editAllData:null, // 编辑订单总信息
      editData:{ // 查看用户所需数据
        provinceoptions: null, // 省份所选项
        cityoptions: null, // 城市所选项
        hospoptions: null, // 医院所选项所选项
        departoptions: null, // 科室所选项
        combooptions: null, // 套餐所选项
        attendingDoctorOptions: null, // 主治医师所选项

        orderTypeoptions: '', // 订单类型所选项
        ispayoptions: '', // 代理商付款状态

        checkReportFilesList:[],//检查报告资料
        dcmReportFilesList:[], //影像资料报告
        invoiceList: [], // 发票信息
        reject:'', // 驳回信息
        payStatusoptions:[], // 支付状态

        jichudisable: null, //基础信息能否修改
        paydisable: null, //支付信息能否修改
        disable: null, //控制表单状态
        dcmReportdisable: null, //控制影像资料的传输权限
        baocundisable: null, //控制保存和保存提交状态
        discountCodeDisable: null,//控制是否能修改优惠码
        returnedAmtDisable: null, //控制是否显示回款金额

        surgeryDate:'', //手术时间
        userType:'', //用户类型
        orderAmt: '' , // 订单金额
        discountCode:'',//储存最初的优惠码
        discountPercent:'', //折扣力度
        urgentAmt:'', //加急金额
        orderReturnedAmt:'', //回款金额
        discountCodeAdopt: true, //优惠码是否通过
      },

      informationconfirm: false, // 控制重建结果弹窗
      resultlist:{}, // 重建结果信息
      imgsrc: '', //二维码生成地址
      imgurl:'', //二维码生成网址
      huanzhe:''//储存患者姓名
    };
  },
  components:{
    // dateq,
    selectq, // 单选框组件
    elementPagination,
    orderadd, // 添加组件
    ordercheck, // 查看订单
    orderedit, // 编辑订单
    informationresult, // 查看重建结果组件
    morePopover, //展示更多下拉框
  },
  computed:{
    ...mapState([
      'orderStatus', //订单状态
      'orderType',//订单类型
      'payStatus', // 支付状态
      'isPay', //代理商付款状态
      'Permissionslist' // 权限词典
    ])
  },
  watch:{
  },
  methods: {
    // 测试修改状态
    // async changepay(row){
    //   const { data: res } = await orderCheckpay({ orderId: row.orderId })
    //   console.log(res);
    // },

    // ordervisible
    getOrderList(ordername,sortType){
      // console.log(this.dayvalue2);
      if(this.dayvalue2!=null){
        this.queryData.startDate= this.dayvalue2[0]
        this.queryData.endDate= this.dayvalue2[1]
      }else{
        this.queryData.startDate=''
        this.queryData.endDate=''
      }
      this.nodatetype=''
      this.ordervisible = true
      this.urgenthide = false //默认隐藏加急列
      setTimeout(async() =>{
        const { data:res } = await orderList({
          ...this.queryData,
          orderStatus: this.orderStatusmodel.key,
          orderType: this.orderTypemodel.key,
          sortField: ordername, //给哪一列排序
          sortType: sortType, //排序类型升序或降序
        })
        if(res.code===20000){
          this.orderList = res.data.orders
          this.orderList.map((e)=>{
            if(e.urgentStatus || e.weekDay){
              //有加急订单 或者是有 周末订单
              this.urgenthide = true //显示加急列
            }
          })
          console.log(this.orderList);
          this.total=res.data.totals*1

          this.ordervisible = false
          this.nodatetype='暂无数据'
        }else{
          this.ordervisible = false
          this.nodatetype='暂无数据'
        }
        let height = document.getElementById('searchbox').offsetHeight + 75
        this.tableHeight = getHeight('orderbox',height)
      },1000)
      
      // console.log(res);
    },
    // 时间控制
    daybian () {
      console.log(this.dayvalue2);
    },
    sortthiscolumn(column){
      // console.log(column);
      // console.log(column.order);
      if(column.order=='ascending'){
        
        if(column.prop=='provinceName'){
          this.getOrderList('provinceId',1)
          this.sortField = 'provinceId'
          this.sortType = 1
        }else if(column.prop=='cityName'){
          this.getOrderList('cityId',1)
          this.sortField = 'cityId'
          this.sortType = 1
        }else if(column.prop=='hospName'){
          this.getOrderList('hospId',1)
          this.sortField = 'hospId'
          this.sortType = 1
        }else if(column.prop=='comboName'){
          this.getOrderList('comboId',1)
          this.sortField = 'comboId'
          this.sortType = 1
        }else if(column.prop=='departName'){
          this.getOrderList('departId',1)
          this.sortField = 'departId'
          this.sortType = 1
        }else{
          // ascending 为升序
          this.getOrderList(column.prop,1)
          this.sortField = column.prop
          this.sortType = 1
        }

      }else if(column.order=='descending'){

        if(column.prop=='provinceName'){
          this.getOrderList('provinceId',0)
          this.sortField = 'provinceId'
          this.sortType = 0
        }else if(column.prop=='cityName'){
          this.getOrderList('cityId',0)
          this.sortField = 'provinceId'
          this.sortType = 0
        }else if(column.prop=='hospName'){
          this.getOrderList('hospId',0)
          this.sortField = 'provinceId'
          this.sortType = 0
        }else if(column.prop=='comboName'){
          this.getOrderList('comboId',0)
          this.sortField = 'provinceId'
          this.sortType = 0
        }else if(column.prop=='departName'){
          this.getOrderList('departId',0)
          this.sortField = 'provinceId'
          this.sortType = 0
        }else{
          // ascending 为降序
          this.getOrderList(column.prop,0)
          this.sortField = column.prop
          this.sortType = 0
        }

      }else{
        // 默认按照时间排序排序
        this.getOrderList('createTime',0)
        this.sortField = 'createTime'
        this.sortType = 0
      }
    },
    // 点击重置
    chongzhi:makeDebounce(function(){
      this.queryData.page=1
      this.queryData.name=''
      this.dayvalue2=null
      this.queryData.startDate=''
      this.queryData.endDate=''
      this.orderStatusmodel=''
      this.orderTypemodel=''
      this.getOrderList()
    },300),
    // 点击搜索时
    search:makeDebounce(function(){
      this.queryData.page=1
      this.getOrderList(this.sortField,this.sortType)
    },300),
    // 开始时间改变时触发
    detEndDate(){
      this.queryData.endDate=''
    },
    // 结束时间的限制
    optionsFn(date) {
      return date >= this.queryData.startDate
    },
    //动态拼接二维码地址
    insertStr(soure, start, newStr){   
      return soure.slice(0, start) + newStr + soure.slice(start);
    },
    //点击查看重建结果时
    getinformation: makeDebounce(async function(row){
      let wPath = window.document.location.href;
      let pathName =  this.$route.path;
      let pos = wPath.indexOf(pathName);
      let localhostPath = wPath.substring(0, pos);
      let pathindex = localhostPath.indexOf('#')
      var newpath =this.insertStr(localhostPath,pathindex,"mobile/");
      console.log(newpath);
      console.log(pathindex);
      console.log(localhostPath);
      console.log(row);
      const { data: res } = await checkInformationResult({orderId:row.orderId})
      console.log(res);
      if(res.code === 20000){
        this.informationconfirm=true
        this.resultlist = res.data.result
        console.log(`${newpath}/wechat/viewer?code=${this.resultlist.accessCode}`);
        // /#/viewer?code=" + this.resultlist.accessCode
        QRCode.toDataURL(`${newpath}/wechat/viewer?code=${this.resultlist.accessCode}`,{ width: 200, height: 200 }, (err, url)=> {
          this.imgsrc = url;
        })
        this.imgurl =`${newpath}/wechat/viewer?code=${this.resultlist.accessCode}`
        this.huanzhe =row.patientName
        console.log(this.resultlist);
      }else{
        notifys('info',res.message)
      }
    }),
    // 点击提交审核
    async commits(row){
      console.log(row);
      this.$q.dialog({
        title: '提交审核',
        message: `你确定要提交此订单吗?`,
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
            const { data: res } = await orderCommit({orderId: row.orderId})
            console.log(res);
            if(res.code===20000){
              this.getOrderList()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
              console.log(e);
          }
      })
      
    },
    // 点击删除时
    async orderDel(row){
      this.$q.dialog({
        title: '删除',
        message: `你确定要删除此订单吗?`,
        ok: { label: '确定',color: 'red'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
            const {data:res} = await orderDelete({orderId:row.orderId})
            console.log(res);
            if(res.code===20000){
              // 判断当前删除的是否为最后一页的最后一个记录
              // 遍历词库列表
              this.orderList.forEach((item, index) => {
                // 如果当前id是要删除的id
                if (item.id === row.id) {
                  // 判断规则:（当前页码-1）*页容量+要被删除项的索引+1===总条数 && 判断被删项索引是否为0 && 当前不是第一页
                  if (
                    (this.queryData.page - 1) * this.queryData.pageSize + index + 1 ===
                      this.total &&
                    index === 0 &&
                    this.queryData.page > 1
                  ) {
                  // 如果是就将页码回到上一页
                  this.queryData.page--;
                  }
                }
              });
              this.getOrderList()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
            console.log(e);
          }
      })
    },
    // 点击查看时
    checkorder: makeDebounce(async function(row) {
      const token = getItem('token');
      this.checkData.userType = jwt.decode(token).userType

      const {data:res} = await orderCheck({id:row.orderId})
      console.log(res);
      if(res.code===20000){
        this.checkAllData = res.data.order //储存总数据
        // 控制是否显示回款金额 只有商务和系统管理员可以显示回款金额
        this.checkData.returnedAmtDisable = this.checkData.userType ==2 || this.checkData.userType ==3 ? true : false, 
        console.log(this.checkAllData);
        // 回款金额
        this.checkData.orderReturnedAmt = this.checkAllData.orderReturnedAmt ? this.checkAllData.orderReturnedAmt: ''
        // 加急金额
        this.checkData.urgentAmt = this.checkAllData.urgentAmt ? this.checkAllData.urgentAmt: ''
        // 优惠码
        this.checkData.discountCode = this.checkAllData.discountCode ? this.checkAllData.discountCode: ''
        //折扣力度
        this.checkData.discountPercent = this.checkAllData.discountPercent ? this.checkAllData.discountPercent: ''
        //支付流水号
        this.checkData.payId = this.checkAllData.orderPay ? this.checkAllData.orderPay.payId : ''

        this.checkData.orderTypeoptions=this.checkAllData.orderType!=undefined? optionget(this.orderType,this.checkAllData.orderType) : ''  //订单类型

        this.checkData.ispayoptions=this.checkAllData.isPay!=undefined? optionget(this.isPay,this.checkAllData.isPay) : ''  //代理商是否付款

        this.checkData.surgeryDate=this.checkAllData.surgeryDate!=undefined? this.checkAllData.surgeryDate : ''  //手术时间

        this.checkData.orderStatusoptions=optionget(this.orderStatus,this.checkAllData.orderStatus) // 订单状态
        this.checkData.payStatusoptions=optionget(this.payStatus,this.checkAllData.payStatus) // 支付状态

        this.checkData.checkReportFilesList = [] //清空检查报告
        this.checkData.dcmReportFilesList =[] // 清空影像资料
        this.checkData.invoiceList=[] // 清空发票信息
        this.checkData.reject=''
        
        // console.log(this.checkAllData.reconstructionVo);
        if(this.checkAllData.reconstructionVo){
          this.checkData.reject = `${this.checkAllData.reconstructionVo.informationComments}`
        }
        if(this.checkAllData.orderCheck){
          this.checkData.reject =  `
          ${this.checkData.reject}
          ${this.checkAllData.orderCheck.comments}`
        }
        // console.log(this.checkData.reject);
        if(this.checkAllData.informationList){
          this.checkAllData.informationList.map(item=>{
            if(item.informationType == 0){
              this.checkData.checkReportFilesList.push(item)
            } else{
              this.checkData.dcmReportFilesList.push(item)
            }
          })
        }
        if(this.checkAllData.invoice){
          this.checkData.invoiceList.push(this.checkAllData.invoice)
        }

        this.checkconfirm = true
      }else{
        notifys('negative',res.message)
      }
    }),
    //关闭查看订单时
    closecheckdialog(){
      this.checkconfirm = false
    },
    // 点击编辑时
    editorder: makeDebounce(async function (row) {
        const token = getItem('token');
        this.editData.userType = jwt.decode(token).userType
        console.log(this.editData.userType);
        const {data:res} = await orderCheck({id:row.orderId})
        console.log(res);
        if(res.code===20000){
          this.editAllData = res.data.order //订单信息储存总数据 dcmReportdisable
          console.log(this.editAllData);
          //控制基础信息是否禁用
          this.editData.jichudisable = (this.editAllData.orderStatus==0 || this.editAllData.orderCheck.checkStatus == 2) && this.editAllData.payStatus==0 ? false : true , 
          //控制订单金额是否禁用
          // this.editData.paydisable = (this.editAllData.orderStatus==0 || this.editAllData.orderCheck.checkStatus == 2) && this.editAllData.payStatus==0 && this.editData.userType!=0 && this.editData.userType!=5 ? false : true , 
          //控制除了基础信息以外信息是否禁用
          this.editData.disable=this.editAllData.orderStatus==0 || this.editAllData.orderCheck.checkStatus == 2 ? false : true , 
          //控制上传删除影像资料是否禁用
          this.editData.dcmReportdisable=this.editAllData.orderStatus==0 || this.editAllData.reconstructionVo.informationStatus == 2 ? false : true ,
          // 控制保存,保存并提交是否禁用
          this.editData.baocundisable=this.editAllData.orderStatus==0 || this.editAllData.reconstructionVo.informationStatus == 2 || this.editAllData.orderCheck.checkStatus == 2? false : true 
          // 控制优惠码是否能修改 未支付的订单可以修改优惠码
          this.editData.discountCodeDisable = (this.editAllData.orderStatus==0 || this.editAllData.orderCheck.checkStatus == 2) && this.editAllData.payStatus==0 ? false : true, 
          // 控制是否显示回款金额 只有商务和系统管理员可以显示回款金额
          this.editData.returnedAmtDisable = this.editData.userType ==2 || this.editData.userType==3 ? true : false, 

          // 回款金额
          this.editData.orderReturnedAmt = this.editAllData.orderReturnedAmt ? this.editAllData.orderReturnedAmt: ''
          // 加急金额
          this.editData.urgentAmt = this.editAllData.urgentAmt ? this.editAllData.urgentAmt: ''
          // 优惠码
          this.editData.discountCode = this.editAllData.discountCode ? this.editAllData.discountCode: ''
          //折扣力度
          this.editData.discountPercent = this.editAllData.discountPercent ? this.editAllData.discountPercent: ''


          this.checkAlllist.province=[] 
          this.checkAlllist.city=[] 
          this.checkAlllist.hosp=[] 
          this.checkAlllist.depart=[] 
          this.checkAlllist.combo=[] 
          this.checkAlllist.attendingDoctor=[] //清空主治医师

          this.checkAlllist.province=await getprovince() // 获取所有省份
          this.checkAlllist.city= await getcity(this.editAllData.provinceId) // 获取所有城市
          this.checkAlllist.hosp=await gethosplist(this.editAllData.cityId) //获取所有医院
          this.checkAlllist.depart=await getdepart(this.editAllData.hospId) //获取所有科室
          this.checkAlllist.combo=await getcombo(this.editAllData.hospId,this.editAllData.cityId) //获取所有套餐
          this.checkAlllist.attendingDoctor=await getattendingDoctor(this.editAllData.departId) //获取所有主治医师

          this.editData.orderAmt = this.editAllData.orderAmt ? JSON.parse(JSON.stringify(this.editAllData.orderAmt)) : ''

          // 获取城市所选项
          this.editData.cityoptions = getoptions(this.checkAlllist.city,this.editAllData.cityId)
          // 获取省份所选项
          this.editData.provinceoptions = getoptions(this.checkAlllist.province,this.editAllData.provinceId);
          //获取所选医院
          this.editData.hospoptions=getoptions(this.checkAlllist.hosp,this.editAllData.hospId) 
          // 获取所选科室
          this.editData.departoptions=getoptions(this.checkAlllist.depart,this.editAllData.departId) 
          //获取所选套餐
          this.editData.combooptions=getoptions(this.checkAlllist.combo,this.editAllData.comboId) 
          //获取所选主治医生
          this.editData.attendingDoctorOptions=getdocotrKey(this.checkAlllist.attendingDoctor,this.editAllData.attendingDoctor) 
          console.log('主治医师', this.editData.attendingDoctorOptions);
          
          // 如果是试用订单
          if(this.editAllData.orderType == 0){
            // 显示为0 但是 订单金额是正常金额
            this.editData.orderAmt = this.editData.combooptions.comboAmt
          }

          this.editData.orderTypeoptions=this.editAllData.orderType!=undefined? optionget(this.orderType,this.editAllData.orderType) : ''  //订单类型

          this.editData.ispayoptions=this.editAllData.isPay!=undefined? optionget(this.isPay,this.editAllData.isPay) : ''  //代理商是否付款

          this.editData.surgeryDate=this.editAllData.surgeryDate!=undefined? this.editAllData.surgeryDate : ''  //手术时间

          this.editData.payStatusoptions=optionget(this.payStatus,this.editAllData.payStatus) // 支付状态

          this.editData.checkReportFilesList = [] //清空检查报告
          this.editData.dcmReportFilesList =[] // 清空影像资料
          this.editData.invoiceList=[] // 清空发票信息

          // 获取资料审核驳回信息
          if(this.editAllData.reconstructionVo){
            this.editData.reject = `${this.editAllData.reconstructionVo.informationComments}`
            if (this.editAllData.reconstructionVo.comments) {
              this.editData.reject = `${this.editAllData.reconstructionVo.comments}`
            }
          }
          // 获取订单审核驳回信息
          if(this.editAllData.orderCheck){
            this.editData.reject = 
            `${this.editData.reject}
  ${this.editAllData.orderCheck.comments}`
          }
          // 获取影像资料和检查报告
          if(this.editAllData.informationList){
            this.editAllData.informationList.map(item=>{
              if(item.informationType == 0){
                this.editData.checkReportFilesList.push(item)
              } else{
                this.editData.dcmReportFilesList.push(item)
              }
            })
          }
          this.editconfirm = true //打开编辑对话框
        }else{
          notifys('negative',res.message)
        }
      }),
    //编辑窗口关闭时
    closeeditdialog(){
      this.editData.ispayoptions='' // 清空代理商收款状态
      this.editData.surgeryDate='' //清空手术时间
      this.editData.payStatusoptions=[] //清空收款状态
      this.editData.orderTypeoptions='' //清空订单类型
      this.editconfirm=false
      this.$refs.orderedit.$refs.myForm.resetValidation()
    },
    // 点击添加订单时
    tianjia(){
      const token = getItem('token');
      this.addorderdata.userType = jwt.decode(token).userType
      this.addorderdata.paydisable = this.addorderdata.userType!=0 && this.addorderdata.userType!=5 ? false : true , //控制新增订单金额是否禁用
      this.addconfirm =true
    },
    // 新增窗口关闭时
    addconfirmclose(){
      this.addconfirm = false
      this.addorderdata.patientName=''
      this.addorderdata.provinceId=''
      this.addorderdata.cityId=''
      this.addorderdata.hospId=''
      this.addorderdata.comboId=''
      this.addorderdata.departId=''
      this.addorderdata.doctorid='' //清空主医师id

      this.addorderdata.doctorName=''
      this.addorderdata.diagnoseDesc=''
      this.addorderdata.orderDesc=''
      this.addorderdata.orderType=''
      this.addorderdata.ispay=''
      this.addorderdata.surgeryDate=''
      this.addorderdata.orderAmt=''
      this.addorderdata.discountCode='' //清空优惠码
      this.addorderdata.discountCodeAdopt=true //重置折扣通过
      this.addorderdata.discountPercent='' //清空折扣力度

      this.addorderdata.checkReportFilesList=[]
      this.addorderdata.dcmReportFilesList=[]

      this.addorderdata.citylist=[]
      this.addorderdata.hosplist=[]
      this.addorderdata.departslist=[]
      this.addorderdata.combolist=[]
      this.addorderdata.doctorlist=[] //清空主管医生
      this.addorderdata.hospNumber=''
      this.$refs.orderadd.$refs.myForm.resetValidation()
    },
    // 分页发送变化函数
    changepageSize(val){
      this.queryData.pageSize = val
      this.pagination.rowsPerPage = val
      this.getOrderList(this.sortField,this.sortType)
    },
    changePage(val){
      this.queryData.page = val
      this.getOrderList(this.sortField,this.sortType)
    }
  },
};
</script>

<style lang='less' scoped>

body {
  margin: 0;
}
#Order{
  height: 100%;
}
.orderbox{
  height: 90%;
}
.q-card__section--vert {
  padding: 10px;
}
/deep/.el-table td{
  padding: 8px 0 !important;
}
.searchbox{
  display: flex;
  flex-direction: column;
    .search{
    margin-bottom: 1rem;
    .operation{
      // margin-right: .625rem;
      // margin-left: .625rem;
      height: 2.25rem;
      margin: 0 .625rem;
    }
  }
}

.newCaozuobox{
  // display: flex;
  // justify-content: space-between;
  .czTit{
    float: left;
    text-align: right;
    width:50%;
  }
  .czpopoverbtn{
    float: left;text-align: right;width:50%;
  }
}
.loadingbox{
  min-height: 25rem;
}
.tabletop{
  display: flex;
  flex-direction:row-reverse;
  padding: .3125rem 1rem;
}
// .seachReset{
//   margin: .625rem .625rem .625rem .625rem;
//   height: 2.25rem;
// }
.czbtn{
  margin-right: .75rem;
}
.czbtn2{
  margin: .3125rem 0;
}
// .addbtn{
//   display: flex;
//   justify-content: flex-end;
//   align-items: flex-end;
//   flex-wrap: wrap;
//   margin-bottom: 1.25rem;
.el-button{
  margin: 0 .625rem .625rem .625rem;
}
/deep/.el-dialog__body{
  padding: 0 10px 10px !important;
}
/deep/.my-special-class{
  font-size: 14px;
  font-weight: bold;
  color: #909399;
}
.Paginationstyle{
  margin-top: .9375rem;
}
.personalbtn{
  width: 100%;
  border: none;
  text-align: center;
}
.personal{
  padding: 0;
}
.morebtn{
  height: 25.72px;
  margin: 0;
  border: none;
  color: white;
  background-color:#fdd835 !important;
}
// .icon-jiaji:before {
//   font-size: 32px;
// }
</style>
