import { createAPI } from "../utils/request"

export const orderCheckList = data => createAPI('order/orderCheck/list', 'get', data) //订单审核列表

export const orderCheckclaim = data => createAPI('order/orderCheck/claim', 'get', data) //批量领取任务

export const orderCheckagree = data => createAPI('order/orderCheck/agree', 'get', data) //批量审核通过

export const orderCheckhistoryimage = data => createAPI('order/orderCheck/history/image', 'get', data) //查看订单处理历史的图片


export const comboCheckreject = data => createAPI(`order/orderCheck/reject/${data.id}`, 'get', data) //驳回

export const orderCheckpay = data => createAPI(`order/orderCheck/pay/${data.orderId}`, 'get', data) //更新支付状态

export const orderCheckUrgent = data => createAPI(`order/orderCheck/urgent/${data.orderId}`, 'get', data) //修改订单加急状态

export const orderCheckOrderDay = data => createAPI(`order/orderCheck/orderDay/${data.orderId}`, 'get', data) //设置周末或者工作日

export const orderCheckDesc = data => createAPI(`order/orderCheck/checkDesc/${data.checkId}`, 'get', data) //设置备注

export const orderCheckinvoice = (data,onUploadProgress) => createAPI(`order/orderCheck/invoice/${data.get('orderId')}`, 'post', data, {onUploadProgress}) //上传发票信息

export const orderCheckhistory = data => createAPI(`order/orderCheck/history/${data.orderId}`, 'get', data) //查看订单处理历史

export const orderCheckresult = data => createAPI(`order/orderCheck/result/${data.orderId}`, 'get', data) //查看重建结果

export const orderCheck = data => createAPI(`order/userOrder/get/${data.id}`, 'get', data) //订单详细

export const orderInfo = data => createAPI(`order/orderCheck/orderInfo/${data.checkId}`, 'get', data) //订单属性详细






