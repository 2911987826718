<template>
    <div>
        <!-- 普通影像资料下载链接生成弹窗 -->
        <q-dialog v-model="downdatas.downconfig" persistent @hide='downhide'>
            <q-card style="width: 500px">
                <q-card-section>
                <div class="text-h6">{{downdatas.downfileName}}</div>
                </q-card-section>
                <q-card-section style="padding: 0 16px;">
                <div>{{downdatas.downstepName}}</div>
                </q-card-section>
                <q-card-section class="q-pt-none">
                    <q-linear-progress size="25px" :value="downdatas.downcheckprogress" color="primary">
                        <div class="absolute-full flex flex-center">
                            <q-badge color="white" text-color="primary" :label="downdatas.downcheckprogressLabel" />
                        </div>
                    </q-linear-progress>
                </q-card-section>

                <q-card-actions align="right" class="text-primary">
                <q-btn flat label="确定" @click='downhide' />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>
<script>
export default {
    props: {
        downdata: {
            //生成链接弹窗信息
            type: Object,
            required: true,
        },
    },
    data() {
        return {
        }
    },
    created(){
    },
    computed:{
        downdatas: {
            get() {
                return this.downdata;
            },
            set(v) {
                this.$emit("update:downdata", v);
            },
        },
    },
    methods:{
        downhide(){
            this.$emit("downhide")
        }
    },
}
</script>
<style lang="less" scoped>

</style>
