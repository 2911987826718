import { createAPI } from "../utils/request"

export const provinceList = data => createAPI('/base/province/list', 'get', data) //省份数据

export const cityList = data => createAPI(`base/city/list/${data.provinceId}`, 'get', data) //城市数据

export const hospList = data => createAPI(`base/hosp/list`, 'get', data) //医院数据

export const departList = data => createAPI(`base/depart/list/${data.hospId}`, 'get', data) //科室数据数据

export const comboList = data => createAPI(`base/combo/getCombo/${data.cityId}/${data.hospId}`, 'get', data) //根据城市医院获取套餐

export const attendingDoctorList = data => createAPI(`acl/user/doctorByDepart/${data.departId}`, 'get', data) //根据科室查询主治医师

export const returnedAmt = data => createAPI(`base/combo/returnedAmt/${data.cityId}/${data.hospId}/${data.comboId}`, 'get', data) //根据 城市 医院 套餐 查询回款金额






