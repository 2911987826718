import { render, staticRenderFns } from "./informationDown.vue?vue&type=template&id=2ab40fc3&scoped=true&"
import script from "./informationDown.vue?vue&type=script&lang=js&"
export * from "./informationDown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ab40fc3",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QLinearProgress,QBadge,QCardActions,QBtn});
